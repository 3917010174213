@import 'src/styles/variables';
@import 'src/styles/fonts';
@import 'src/styles/animations';

$MAIN_CLASS: 'BasicInfo';

.#{$MAIN_CLASS} {
  width: 100%;
  max-width: 600px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: $spacing_7xlarge * 2;
}
.section {
  &__fields {
    &-field-upload {
      display: flex;
      margin-top: $spacing_7xlarge;
      margin-bottom: $spacing_5xlarge !important;
      width: 600px;
      .UploadThumb {
        margin-left: $spacing_7xlarge;
      }
      &-thumb {
        width: 100px;
        height: 100px;
        position: relative;
        background: map-get($neutral-colors, '50');
        border: 2px dashed map-get($neutral-colors, '200');
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: map-get($neutral-colors, '700');
        small {
          padding-top: $spacing_large;
        }
        .thumb-preview {
          display: flex;
          width: 100px;
          height: 100px;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          position: absolute;
          top: -2px;
          left: -2px;
          border: 1px solid map-get($neutral-colors, '300');
          z-index: 20;
          .actions {
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            left: 0;
            top: 0;
            width: 100px;
            height: 100px;
            z-index: 22;
            opacity: 0;
            transition: opacity 0.2s linear;
            background-color: rgba(255, 255, 255, 0.8);
            .flight-button--link--has-underline:hover:enabled,
            .flight-button--link--has-underline:focus:enabled {
              outline: auto;
              outline-offset: -5px;
              height: 90px;
            }
          }
          .bt__remove {
            color: map-get($reds, '500') !important;
            padding-top: 10px;
          }
          img,
          svg {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
        .logo-upload-input {
          display: block !important;
          width: 100px;
          height: 100px;
          overflow: hidden;
          position: absolute;
          left: 0;
          top: 0;
          background-color: aliceblue;
          z-index: 15;
          opacity: 0;
          outline: none;
          &:hover,
          &:active {
            outline: none;
          }
        }
        &:hover,
        &:active {
          .actions {
            opacity: 1;
            outline: none;
          }
        }
      }
    }
  }
  &__deployments {
    margin-bottom: $spacing_5xlarge !important;
    width: 600px;
    .deployment__item {
      display: flex;
      padding: $spacing_large;
      align-items: center;
      justify-content: space-between;
      border: 1px solid map-get($neutral-colors, '200');
      &-logo {
        display: flex;
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        img,
        svg {
          object-fit: contain;
          width: 100%;
          height: auto;
        }
      }
      &-info {
        flex-direction: column;
        margin: auto $spacing_xlarge;
      }
      &-content {
        width: 50%;
        align-items: center;
        justify-content: flex-end;
        > strong {
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
          color: map-get($neutral-colors, '700');
          margin: auto $spacing_small;
        }
        .flight-overflow-menu {
          .flight-button.flight-overflow-menu__trigger {
            background-color: transparent;
            width: 45px;
            height: 45px;
            > svg {
              width: 24px;
              height: 24px;
              transform: rotate(90deg);
            }
          }
        }
      }
      &-content,
      &-info {
        position: relative;
        height: 50%;
        display: flex;
        > h5 {
          font-size: 14px;
          line-height: 18px;
          margin: 0;
          display: flex;
          align-items: center;
          svg {
            width: 16px;
            height: 16px;
            padding-inline-start: 5px;
          }
        }
        > span {
          font-size: 12px;
          line-height: 15px;
          color: map-get($neutral-colors, '700');
        }
        .flight-button--link {
          color: map-get($reds, '400');
          font-weight: bold !important;
          &:hover:enabled,
          &:focus:enabled {
            color: map-get($reds, '400');
            font-weight: bold !important;
          }
        }
      }
    }
  }
}
