@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/fonts';

.ConnectorCardItem {
  &__wrapper {
    margin-bottom: $spacing_5xlarge;
    position: relative;

    .flight-overflow-menu {
      position: absolute;
      z-index: 10;
      top: 15px;
      right: 5px;
      .flight-button.flight-overflow-menu__trigger {
        background-color: transparent;
        width: 45px;
        height: 45px;
        > svg {
          width: 24px;
          height: 24px;
          transform: rotate(90deg);
        }
      }
    }
    &.is-selected {
      .flight-card {
        background-color: rgba(221, 236, 255, 0.5);
        border-color: map-get($blues, '400');
      }
    }
  }

  &__border.flight-card {
    @include outline-none;
    cursor: pointer;
    background-color: map-get($primary-colors, 'white');
    border-radius: 10px;
    border: $border-width solid map-get($neutral-colors, '200');
    box-sizing: border-box;
    width: 342px;
    min-height: 195px;
    padding: 30px;
    align-items: flex-start;
    position: relative;
    z-index: 7;
    flex-direction: row;
    transition: all 0.2s;
    &:hover,
    &:focus,
    &:focus-within {
      background-color: rgba(221, 236, 255, 0.5);
      border-color: map-get($blues, '400');
    }

    .flight-card {
      @extend .sso_font;

      &__header {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        .connector {
          &__header {
            display: flex;
            align-items: center;
            margin-top: 0;
            margin-bottom: $spacing_xlarge;
            text-overflow: ellipsis;
            overflow: hidden;
            color: map-get($blues, '500');
            font-size: 18px;
            line-height: 22px;
          }
          &__description,
          &__owner {
            font-size: 13px;
            line-height: 18px;
            font-weight: normal;
            color: map-get($neutral-colors, '700');
            padding-bottom: $spacing_xlarge;
          }
        }
      }
      &__image {
        display: flex;
        width: 60px;
        height: 60px;
        align-items: baseline;
        justify-content: center;
        overflow: hidden;
        margin-right: $spacing_2xlarge;
        border-radius: 6px;
        img {
          width: 60px;
          height: 60px;
          object-fit: cover;
        }
      }
      &__body {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        .connector {
          &__description {
            font-size: 14px;
            line-height: 18px;
            color: map-get($neutral-colors, '500');
          }
        }
      }

      &__footer {
        @extend .sso_font--small;
        color: map-get($neutral-colors, '700');
        align-items: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-top: 12px;
      }
    }
  }

  &__inactive {
    .flight-card {
      &__footer {
        color: map-get($reds, '500');
      }
    }
  }

  &__connector-icon {
    object-fit: contain;
    width: 100%;
    height: auto;
    background-position: top;
  }
}
