@import 'src/styles/variables';

$MAIN_CLASS: 'DeleteConnectorModal';

.#{$MAIN_CLASS} {
  &__alert {
    background-color: rebeccapurple;
    .flight-modal__content {
      font-size: 14px;
      line-height: 18px;
      p {
        margin: 0 0 $spacing_2xlarge;
      }
      ul {
        margin: 0 0 0 $spacing_2xlarge;
        padding: 0;
        li {
          margin-bottom: $spacing_2xlarge;
        }
      }
    }
    .flight-button--primary:enabled {
      color: #fff;
      background-color: map-get($reds, '400');
      &:hover,
      &:focus {
        background-color: map-get($reds, '500');
      }
    }
    .flight-modal__content + .flight-modal__footer {
      padding: $spacing_medium;
    }
    &_footer {
      text-align: right;
      padding-bottom: $spacing_large;
    }
  }
  .modal_buttons {
    text-align: right;
    button {
      margin-left: 8px;
    }
  }
}
