@import 'src/styles/variables';

.Preview {
  input,
  .flight-text-input__input {
    background-image: none !important;
    background: map-get($neutral-colors, '50') !important;
    background-size: 0% !important;
    background-image: none !important;
    background-attachment: none !important;
    padding-right: 0 !important;
  }
  .flight-text-input__icon-trailing {
    cursor: pointer !important;
    right: 0;
    top: 2px;
    padding: $spacing_medium;
    z-index: 10000 !important;
  }
}
[id^='__lpform'] {
  display: none !important;
  opacity: 0 !important;
  visibility: hidden !important;
  transform: translateX(99999px) !important;
  width: 0px !important;
  height: 0px !important;
  overflow: hidden !important;
}
