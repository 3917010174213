@import 'src/styles/variables';
.secret {
  background: #141d39;
  height: 100vh;
  overflow: hidden;
  margin: 0;
  position: fixed;
  z-index: 984798048097234;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: window-show 0.5s ease-in 0s 1 forwards;

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  .justin {
    position: absolute;
    width: 100%;
    color: #fff;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &.animated {
      opacity: 0;
      animation: window-show 0.5s ease-in 6s 1 forwards;
    }
  }

  .window {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    // transform: translate(-50%, -50%);
    // border: 20px solid #16264b;
    // border-radius: 50%;
    background: linear-gradient(
      to bottom,
      #1a3864 76.6666666667px,
      #1b3159 76.6666666667px,
      #1b3159 153.3333333333px,
      #1b2c4f 153.3333333333px,
      #1b2c4f 230px,
      #182443 230px,
      #182443 306.6666666667px,
      #141d39 306.6666666667px,
      #141d39 383.3333333333px,
      #1a3863 383.3333333333px
    );
    overflow: hidden;
  }
  .window:before,
  .window:after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 50%;
    width: 5px;
    height: 5px;
    border-radius: 50%;
  }
  .window:before {
    box-shadow: 215px 25px 0 0 #fff, 105px -45px 0 0 #fff, 160px -90px 0 0 #fff, 110px -120px 0 -1px #fff,
      105px 70px 0 -1px #fff, 145px 105px 0 0 #fff, -190px -85px 0 0 #fff, -150px -50px 0 0 #fff,
      -160px -130px 0 -1px #fff, -140px 85px 0 0 #fff, -195px 65px 0 0 #fff;
    -webkit-animation: blink 1s ease-in 0s infinite;
    animation: blink 1s ease-in 0s infinite;
  }
  .window:after {
    box-shadow: 150px 0 0 0 #fff, 200px -65px 0 0 #fff, 130px -170px 0 0 #fff, -160px 20px 0 2px #fff,
      -215px 5px 0 0 #fff, -110px 15px 0 -1px #fff, -110px -120px 0 0 #fff, -100px -170px 0 0 #fff;
    -webkit-animation: blink 1s ease-in 0.5s infinite;
    animation: blink 1s ease-in 0.5s infinite;
  }

  .burn {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 100px;
    border-radius: 50%;
    background: none;
    bottom: 70px;
    transform-origin: center top;
    animation: flame-spread 0.5s ease-in 0.5s infinite alternate, launch-burn 5s ease-in 2.5s 1 forwards;
  }
  .burn:before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    height: 250px;
    border-radius: 30px;
    background: linear-gradient(to right, #feda94 25%, #fece5f 25%, #fece5f 75%, #feda94 75%);
    box-shadow: -62.5px -10px 0 -10px #fff, 62.5px -10px 0 -10px #fff;
    animation: flame-start 0.5s ease-in 0s 1, flame-glow 0.5s ease-in 0.5s infinite;
  }
  .burn:after {
    content: '';
    position: absolute;
    left: 50%;
    background: #fff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    bottom: -70px;
    transform: translateX(-50%) translateY(10px);
    opacity: 0;
    box-shadow: 0 50px 0 10px #e6e9ed, -140px 10px 0 0 #e6e9ed, -125px 35px 0 0 #e6e9ed, -100px 30px 0 5px #e6e9ed,
      -60px 0 0 5px #e6e9ed, -60px 40px 0 30px #e6e9ed, -35px 25px 0 10px #e6e9ed, -85px 15px 0 5px #e6e9ed,
      35px 45px 0 10px #e6e9ed, 35px 25px 0 10px #e6e9ed, 75px 40px 0 15px #e6e9ed, 125px 50px 0 15px #e6e9ed,
      155px 20px 0 15px #e6e9ed, 0 20px 0 15px #fff, -185px -15px 0 15px #fff, -205px -45px 0 0 #fff,
      -150px 0px 0 0 #fff, -115px -5px 0 15px #fff, -95px -35px 0 5px #fff, -70px -50px 0 0 #fff, -70px 0px 0 30px #fff,
      -65px -75px 0 0 #fff, -55px -55px 0 0 #fff, -40px -30px 0 5px #fff, -15px -15px 0 5px #fff, 20px -25px 0 5px #fff,
      40px -5px 0 15px #fff, 55px -45px 0 0 #fff, 55px -65px 0 -5px #fff, 75px -70px 0 -5px #fff,
      75px -60px 0 -10px #fff, 75px -40px 0 5px #fff, 90px -25px 0 5px #fff, 90px 5px 0 25px #fff,
      135px -15px 0 20px #fff, 185px -25px 0 10px #fff, 195px -55px 0 10px #fff, 215px -75px 0 0 #fff;
    animation: flame-gas-show 5s ease-in 2.5s 1 forwards, flame-gas 0.5s ease-in 0.5s infinite;
  }

  .space-shuttle {
    width: 500px;
    height: 500px;
    position: absolute;
    left: 50%;
    top: calc(100% - 100px);
    transform: translate(-50%, -50%);
    -webkit-animation: shake 0.125s ease-in 0.5s infinite;
    animation: shake 0.125s ease-in 0.5s infinite, launch 5s ease-in 2.5s 1 forwards;
  }

  .orbiter {
    width: 50px;
    height: 220px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 30%;
    transform: translate(-50%, -50%);
    background: linear-gradient(
        to bottom,
        transparent 45px,
        rgba(0, 0, 0, 0.5) 45px,
        rgba(255, 255, 255, 0.85) 46px,
        transparent 46px,
        transparent 85px,
        rgba(0, 0, 0, 0.5) 85px,
        rgba(255, 255, 255, 0.85) 86px,
        transparent 86px,
        transparent 125px,
        rgba(0, 0, 0, 0.5) 125px,
        rgba(255, 255, 255, 0.85) 126px,
        transparent 126px
      ),
      linear-gradient(to right, white 50%, #d9d9d9 50%);
    border-radius: 50% 50% 0 0/30% 30% 0 0;
    box-shadow: 0 -12px 0 0 #414750;
    > .flybitsFullLogo {
      position: absolute;
      transform: rotate(90deg);
      right: 10px;
      top: 150px;
    }
  }
  .orbiter:before,
  .orbiter:after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .orbiter:before {
    width: 70px;
    bottom: -35px;
    height: 50px;
    background: linear-gradient(-135deg, #d9d9d9 25px, transparent 25px),
      linear-gradient(to right, white 50%, #c5c5c5 50%);
    border-radius: 25px 25px 10px 10px;
    box-shadow: 0 30px 0 -15px #414750;
    z-index: 2;
  }
  .orbiter:after {
    height: 60px;
    bottom: -50px;
    border-left: 3px solid #eceff2;
    border-right: 3px solid #9ea3a9;
    z-index: 2;
  }
  .orbiter_window {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 20px;
  }
  .orbiter_window:before,
  .orbiter_window:after {
    content: '';
    position: absolute;
    width: 14px;
    height: 6px;
    background: #414750;
    border-radius: 5px 0 0 2px;
    left: 50%;
  }
  .orbiter_window:before {
    transform: translateX(-50%) rotate(-8deg) translateX(-9px);
  }
  .orbiter_window:after {
    transform: translateX(-50%) rotate(8deg) translateX(9px) scaleX(-1);
  }
  .orbiter_wing {
    position: absolute;
    left: 50%;
    margin-left: -30px;
    width: 60px;
    height: 25px;
    bottom: 0px;
    z-index: 1;
    border-radius: 12px 0 0 5px;
    box-shadow: -6px -5px 0 0 #434a53;
  }
  .orbiter_wing:before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 29px;
    border-top: 35px solid transparent;
    border-right: 25px solid #434a53;
    border-bottom: 30px solid #434a53;
    border-left: 35px solid transparent;
  }
  .orbiter_wing:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 23px;
    border-top: 35px solid transparent;
    border-left: 30px solid transparent;
  }
  .orbiter_wing--left {
    background: #f2f3f4;
    transform: translateX(-55px);
    .flybitsLogoText {
      display: none;
    }
    .flybitsLogoImage {
      transform: translateX(22px);
    }
  }
  .orbiter_wing--left:after {
    border-right: 25px solid #f2f3f4;
    border-bottom: 25px solid #f2f3f4;
  }
  .orbiter_wing--right {
    background: #aeb2b9;
    transform: translateX(55px) scaleX(-1);
    .flybitsLogoText {
      display: none;
    }
    .flybitsLogoImage {
      transform: translateX(22px);
    }
  }
  .orbiter_wing--right:after {
    border-right: 25px solid #aeb2b9;
    border-bottom: 25px solid #aeb2b9;
  }
  .orbiter_fin {
    position: absolute;
    left: 50%;
    margin-left: -30px;
    width: 60px;
    height: 25px;
    bottom: -15px;
    z-index: 0;
    border-radius: 5px;
  }
  .orbiter_fin--left {
    background: #656d77;
    transform: translateX(-55px) rotate(15deg);
  }
  .orbiter_fin--right {
    background: #565d65;
    transform: translateX(55px) rotate(-15deg);
  }

  .rocket {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 260px;
    border-radius: 50% 50% 0 0/25% 25% 0 0;
    background: linear-gradient(
        to bottom,
        transparent 60px,
        rgba(0, 0, 0, 0.25) 60px,
        rgba(0, 0, 0, 0.25) 62px,
        transparent 62px,
        transparent 110px,
        rgba(0, 0, 0, 0.25) 110px,
        rgba(0, 0, 0, 0.25) 112px,
        transparent 112px,
        transparent 160px,
        rgba(0, 0, 0, 0.25) 160px,
        rgba(0, 0, 0, 0.25) 162px,
        transparent 162px,
        transparent 210px,
        rgba(0, 0, 0, 0.25) 210px,
        rgba(0, 0, 0, 0.25) 212px,
        transparent 212px
      ),
      linear-gradient(to right, map-get($blues, '300') 50%, map-get($blues, '500') 50%);
    z-index: -1;
    top: -12%;
  }
  .rocket:before,
  .rocket:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 20px;
    top: 45%;
  }
  .rocket:before {
    left: -10px;
    background: #aab2bc;
    box-shadow: 0 90px 0 0 #aab2bc;
  }
  .rocket:after {
    right: -10px;
    background: #838c94;
    box-shadow: 0 90px 0 0 #79848e;
  }
  .rocket_booster {
    width: 25px;
    height: 300px;
    position: absolute;
    top: 60px;
    border-radius: 50% 50% 5px 5px/10% 10% 5px 5px;
    box-shadow: 0 10px 0 -2px #393f46;
  }
  .rocket_booster:before {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 30px;
    bottom: 0;
    height: 12px;
    border-radius: 4px;
  }
  .rocket_booster--left {
    left: -35px;
    background: linear-gradient(
        to bottom,
        transparent 60px,
        rgba(0, 0, 0, 0.25) 60px,
        rgba(0, 0, 0, 0.25) 62px,
        transparent 62px,
        transparent 110px,
        rgba(0, 0, 0, 0.25) 110px,
        rgba(0, 0, 0, 0.25) 112px,
        transparent 112px,
        transparent 160px,
        rgba(0, 0, 0, 0.25) 160px,
        rgba(0, 0, 0, 0.25) 162px,
        transparent 162px,
        transparent 210px,
        rgba(0, 0, 0, 0.25) 210px,
        rgba(0, 0, 0, 0.25) 212px,
        transparent 212px
      ),
      linear-gradient(45deg, #e6e9ed 20px, #d1d4d8 20px, #ffffff 50px);
  }
  .rocket_booster--left:before {
    background: linear-gradient(45deg, #e6e9ed 22px, #d1d4d8 22px);
  }
  .rocket_booster--right {
    background: linear-gradient(
        to bottom,
        transparent 60px,
        rgba(0, 0, 0, 0.25) 60px,
        rgba(0, 0, 0, 0.25) 62px,
        transparent 62px,
        transparent 110px,
        rgba(0, 0, 0, 0.25) 110px,
        rgba(0, 0, 0, 0.25) 112px,
        transparent 112px,
        transparent 160px,
        rgba(0, 0, 0, 0.25) 160px,
        rgba(0, 0, 0, 0.25) 162px,
        transparent 162px,
        transparent 210px,
        rgba(0, 0, 0, 0.25) 210px,
        rgba(0, 0, 0, 0.25) 212px,
        transparent 212px
      ),
      linear-gradient(45deg, #91979f 40px, #b9b9b9 40px, #b9b9b9 150px, #d9d9d9 150px);
    right: -35px;
  }
  .rocket_booster--right:before {
    background: #91979f;
  }

  @keyframes blink {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.25;
    }
  }

  @keyframes flame-spread {
    0%,
    100% {
      background: rgba(254, 206, 95, 0.25);
      box-shadow: 0 25px 0 25px rgba(254, 206, 95, 0.15), 62.5px 5px 0 5px rgba(255, 255, 255, 0.05),
        62.5px -10px 0 -10px rgba(255, 255, 255, 0.25), -62.5px 5px 0 5px rgba(255, 255, 255, 0.05),
        -62.5px -10px 0 -10px rgba(255, 255, 255, 0.25);
      width: 60px;
      height: 100px;
      bottom: 50px;
    }
    50% {
      width: 55px;
      height: 90px;
      bottom: 60px;
    }
  }

  @keyframes flame-start {
    0%,
    19% {
      width: 25px;
      opacity: 0;
    }
    20%,
    39% {
      width: 25px;
      opacity: 1;
    }
    40%,
    59% {
      width: 25px;
      opacity: 0;
    }
    60%,
    79% {
      width: 25px;
      opacity: 1;
    }
    100% {
      width: 40px;
      opacity: 1;
    }
  }

  @keyframes launch {
    0% {
      top: calc(100% - 100px);
    }
    25% {
      top: calc(100% - 100px);
    }
    100% {
      top: -130vh;
    }
  }
  @keyframes launch-burn {
    0% {
      bottom: 70px;
    }
    25% {
      bottom: 70px;
    }
    80% {
      bottom: 130vh;
    }
    100% {
      bottom: 130vh;
    }
  }

  @keyframes flame-glow {
    0%,
    100% {
      box-shadow: -62.5px -10px 0 -10px #fff, 62.5px -10px 0 -10px #fff, -62.5px -10px 0 -10px #fff,
        62.5px -10px 0 -10px #fff, 0 0 0 0 #feda94;
    }
    50% {
      box-shadow: -62.5px -10px 0 -10px #fff, 62.5px -10px 0 -10px #fff, -62.5px -10px 8px -10px #fff,
        62.5px -10px 8px -10px #fff, 0 0 10px 0 #feda94;
    }
  }
  @keyframes window-show {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes flame-gas-show {
    0% {
      opacity: 0;
      bottom: -70px;
    }
    15% {
      opacity: 1;
      bottom: -70px;
    }
    80% {
      opacity: 1;
      bottom: -200px;
    }
    100% {
      opacity: 0;
      bottom: -300px;
    }
  }
  @keyframes flame-gas {
    0%,
    100% {
      transform: translateX(-50%) translateY(10px) scale(1.03);
    }
    50% {
      transform: translateX(-50%) translateY(15px) scale(0.99);
    }
  }

  @keyframes shake {
    0%,
    100% {
      transform: translate(-50%, -50%) translateY(0px);
    }
    50% {
      transform: translate(-50%, -50%) translateY(1px);
    }
  }
}
