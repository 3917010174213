@import 'src/styles/variables';
@import 'src/styles/fonts';
@import 'src/styles/animations';

$MAIN_CLASS: 'SharingSettings';

.#{$MAIN_CLASS} {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: $spacing_7xlarge * 2;
}
.section {
  &__fields {
    &-field {
      position: relative;
      max-width: 600px;
      box-sizing: border-box;
      margin-top: $spacing_5xlarge;
      .searching {
        position: absolute;
        z-index: 20;
        right: 12px;
        top: 42px;
      }
    }
    &-organizations {
      display: flex;
      flex-direction: column;
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        width: 600px;
        > div {
          position: relative;
        }
        button {
          transition: opacity 0.2s linear;
          opacity: 0;
          pointer-events: none;
          position: absolute;
          right: 0;
          top: calc(50% - 10px);
        }

        &:hover,
        &:focus {
          button {
            opacity: 1;
            pointer-events: all;
            > span {
              text-decoration: none;
            }
          }
        }
        .organization__item {
          display: flex;
          column-gap: $spacing_large;
          padding: $spacing_large;
          align-items: center;
          border: 1px solid map-get($neutral-colors, '200');
          &-logo {
            display: flex;
            width: 40px;
            height: 40px;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            img,
            svg {
              object-fit: contain;
              width: 100%;
              height: auto;
            }
          }
          &-content {
            position: relative;
            width: 100%;
            height: 100%;
            > h5 {
              font-size: 14px;
              line-height: 18px;
              margin: 0;
              display: flex;
              align-items: center;
              svg {
                width: 16px;
                height: 16px;
                padding-inline-start: 5px;
              }
            }
            > span {
              font-size: 12px;
              line-height: 15px;
              color: map-get($neutral-colors, '700');
            }
            .flight-button--link {
              color: map-get($reds, '400');
              font-weight: bold !important;
              &:hover:enabled,
              &:focus:enabled {
                color: map-get($reds, '400');
                font-weight: bold !important;
              }
            }
          }
        }
        &:not(:last-child) {
          > div {
            border-bottom: none;
          }
        }
      }
      &-search-response {
        position: absolute;
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        width: 600px;
        z-index: 10;
        background-color: #fff;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
        .organization__item-result {
          display: flex;
          column-gap: $spacing_xlarge;
          padding: $spacing_xlarge;
          align-items: center;
          border: 1px solid map-get($neutral-colors, '200');
          transition: opacity 0.4s ease;
          .not-found {
            display: flex;
            font-weight: normal;
            font-size: 14px;
            line-height: 18px;
            color: map-get($neutral-colors, '700');
            justify-content: space-between;
            align-items: center;
            width: 100%;
            p {
              font-size: 14px;
              line-height: 18px;
            }
          }
          &-logo {
            display: flex;
            width: 52px;
            height: 52px;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            img,
            svg {
              object-fit: contain;
              width: 100%;
              height: auto;
            }
          }
          &-content {
            position: relative;
            width: 100%;
            height: 100%;
            > h5 {
              font-size: 16px;
              line-height: 20px;
              margin: 0;
              display: flex;
              align-items: center;
              svg {
                padding-right: 6px;
              }
            }
            > span {
              font-size: 14px;
              line-height: 18px;
              color: map-get($neutral-colors, '700');
            }
            > button {
              position: absolute;
              right: 0;
              top: calc(50% - 20px);
            }
          }
        }
      }
    }
    &-projects {
      display: flex;
      flex-direction: column;
      list-style: none;
      margin: $spacing_2xlarge 0;
      padding: 0;
      li {
        width: 600px;
        > div {
          position: relative;
        }

        .project__status {
          font-weight: bold;
          &.active {
            color: map-get($greens, '600');
          }
        }
        .project__item {
          display: flex;
          column-gap: $spacing_large;
          padding: $spacing_large;
          align-items: center;
          border: 1px solid map-get($neutral-colors, '200');

          opacity: 0.65;
          transition: opacity 0.1s linear;
          &.shared,
          &:hover,
          &:focus,
          &:focus-within {
            opacity: 1;
          }
          &-toggle {
            display: flex;
            width: 40px;
            height: 40px;
            align-items: center;
            justify-content: center;
            overflow: hidden;
          }
          &-logo {
            display: flex;
            width: 40px;
            height: 40px;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            img,
            svg {
              object-fit: contain;
              width: 100%;
              height: auto;
            }
          }
          &-content {
            position: relative;
            width: 100%;
            height: 100%;
            > h5 {
              font-size: 14px;
              line-height: 18px;
              margin: 0;
              display: flex;
              align-items: center;
              svg {
                width: 16px;
                height: 16px;
                padding-inline-start: 5px;
              }
            }
            > span {
              font-size: 12px;
              line-height: 15px;
              color: map-get($neutral-colors, '700');
            }
            .flight-button--link {
              color: map-get($reds, '400');
              font-weight: bold !important;
              &:hover:enabled,
              &:focus:enabled {
                color: map-get($reds, '400');
                font-weight: bold !important;
              }
            }
          }
        }
        &:not(:last-child) {
          > div {
            border-bottom: none;
          }
        }
      }
    }
  }
}
.#{$MAIN_CLASS} {
  .flight-modal {
    &__header {
      font-weight: 800 !important;
      font-size: 20px;
      line-height: 25px;
      &__close {
        width: 40px;
        > svg {
          width: 24px;
          height: 24px;
        }
      }
    }
    &.revoke {
      .flight-button--primary {
        color: #fff;
        background-color: map-get($reds, '400');
        margin-left: $spacing_xlarge;
        &:hover:enabled,
        &:focus:enabled {
          background-color: map-get($reds, '500');
        }
      }
    }
    &.sharing {
      max-width: 352px !important;
      .flight-modal__content {
        font-size: 14px;
        line-height: 18px;
        p {
          margin: 0 0 $spacing_2xlarge;
        }
        ul {
          margin: 0 0 0 $spacing_2xlarge;
          padding: 0;
          li {
            margin-bottom: $spacing_2xlarge;
          }
        }
        .checkboxes {
          margin-bottom: $spacing_7xlarge;
          .checkbox {
            display: flex;
            margin-bottom: $spacing_2xlarge;
            .flight-checkbox-wrapper {
              align-items: flex-start;
              .flight-checkbox-label {
                color: map-get($primary-colors, 'black');
                font-size: 14px;
                line-height: 18px;
              }
            }
          }
        }
        .flight-button--primary {
          margin-left: $spacing_xlarge;
        }
      }
      .flight-modal__content + .flight-modal__footer {
        padding: $spacing_medium;
      }
    }
  }
  .warning {
    display: flex;
    background-color: #fdf9d4;
    padding: $spacing_large;
    max-width: 600px;
    box-sizing: border-box;
    .icon {
      width: $spacing_7xlarge;
      padding-right: $spacing_small;
      svg {
        width: 20px;
        height: 20px;
      }
    }
    .message {
      font-size: 14px;
      line-height: 18px;
      display: flex;
      flex-direction: column;
      h6 {
        color: map-get($neutral-colors, '900');
        opacity: 0.95;
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        margin: 0;
      }
      p {
        color: map-get($primary-colors, 'black');
      }
      .links {
        display: flex;
        width: 100%;
        justify-content: flex-end;
      }
      a {
        color: map-get($neutral-colors, '900');
        text-align: right;
        opacity: 0.9;
        font-weight: bold;
        text-decoration: none;
      }
      .approve-link {
        margin-left: $spacing_2xlarge;
        &.flight-button--link {
          color: map-get($neutral-colors, '900');
          text-align: right;
          opacity: 0.9;
          font-weight: bold !important;
          text-decoration: none;
        }
      }
    }
  }
  &__alert {
    &_footer {
      text-align: right;
      padding-bottom: $spacing_large;
    }
  }
}
