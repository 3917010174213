@import 'variables';
@import 'mixins';
/**
 * ----------------------------------------
 * example classes
 * ----------------------------------------
 */
.swing-in-top-fwd {
  animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}
.swing-out-top-bck {
  animation: swing-out-top-bck 0.45s cubic-bezier(0.6, -0.28, 0.735, 0.045) both;
}

/**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
@keyframes swing-in-top-fwd {
  0% {
    transform: rotateX(-100deg);
    transform-origin: top;
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    transform-origin: top;
    opacity: 1;
  }
}

/**
 * ----------------------------------------
 * animation swing-out-top-bck
 * ----------------------------------------
 */
@keyframes swing-out-top-bck {
  0% {
    transform: rotateX(0deg);
    transform-origin: top;
    opacity: 1;
  }
  100% {
    transform: rotateX(-100deg);
    transform-origin: top;
    opacity: 0;
  }
}

/**
 * ----------------------------------------
 * animation dash
 * ----------------------------------------
 */
@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes wiggle {
  to {
    transform: translateX(6px);
  }
}

@keyframes scale {
  0% {
    transform: scale(0);
  }
  15% {
    transform: scale(0);
  }
  19% {
    transform: scale(1.3);
  }
  22% {
    transform: scale(1);
  }
  70% {
    transform: scale(1);
  }
  75% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes scale_rotate {
  0% {
    transform: scale(1.5) rotate(90deg);
    opacity: 0;
  }
  35% {
    transform: scale(1.5) rotate(90deg);
    opacity: 0;
  }
  39% {
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
  42% {
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
  60% {
    transform: scale(1) rotate(0deg);
    opacity: 1;
  }
  65% {
    transform: scale(1.5) rotate(90deg);
    opacity: 0;
  }
  100% {
    transform: scale(1.5) rotate(90deg);
    opacity: 0;
  }
}

@include keyframe-animation(spin) {
  0% {
    @include prefix-property(transform, rotate(0deg));
  }
  100% {
    @include prefix-property(transform, rotate(360deg));
  }
}

@include keyframe-animation(spin-accel) {
  0% {
    @include prefix-property(transform, rotate(0deg));
  }
  20% {
    @include prefix-property(transform, rotate(45deg));
  }
  40% {
    @include prefix-property(transform, rotate(110deg));
  }
  60% {
    @include prefix-property(transform, rotate(250deg));
  }
  80% {
    @include prefix-property(transform, rotate(315deg));
  }
  100% {
    @include prefix-property(transform, rotate(360deg));
  }
}

@include keyframe-animation(color-grey-change) {
  0% {
    @include prefix-property(background, map-get($neutral-colors, '50'));
  }
  50% {
    @include prefix-property(background, map-get($neutral-colors, '100'));
  }
  100% {
    @include prefix-property(background, map-get($neutral-colors, '50'));
  }
}

@include keyframe-animation(fade-in-top) {
  0% {
    @include prefix-property(transform, translateY(-10px));
    opacity: 0;
  }
  100% {
    @include prefix-property(transform, translateY(0));
    opacity: 1;
  }
}

@include keyframe-animation(fade-in-bottom) {
  0% {
    @include prefix-property(transform, translateY(10px));
    opacity: 0;
  }
  100% {
    @include prefix-property(transform, translateY(0));
    opacity: 1;
  }
}
