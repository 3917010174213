@import 'src/styles/variables';
@import 'src/styles/fonts';
@import 'src/styles/mixins';
@import 'src/styles/animations';

$MAIN_CLASS: 'project_users_list';
$CONTENT_CLASS: $MAIN_CLASS + '__content';
$FORM_CLASS: $MAIN_CLASS + '__form';

.#{$CONTENT_CLASS} {
  &__infos {
    &-wrapper {
      margin-top: $spacing_7xlarge;
      &_list {
        box-sizing: border-box;
        width: 100%;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: map-get($neutral-colors, '600');
        display: flex;
        flex-direction: column;
        border: 1px solid map-get($neutral-colors, '200');
        border-bottom: 0;
        margin-top: $spacing_2xlarge;
      }
    }
  }
}
