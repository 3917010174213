@import 'src/styles/app';

.BannerImage {
  background-image: linear-gradient(146.42deg, #192984 -8.62%, #061436 120.43%);
  background-size: cover;
  background-color: #1e2b4a;
  background-repeat: no-repeat;
  width: 50vw;
  height: 100%;
  bottom: 0px;
  position: relative;
  overflow: hidden;
  &__image {
    position: absolute;
    content: '';
    width: 50vw;
    height: 100%;
    bottom: 0px;
    background: url(../../assets/banner_01.png) no-repeat 45px 100px;
    background-size: 864px 799px;
    pointer-events: none;
    will-change: transform;
  }
  &__wrapper {
    height: 100%;
    width: inherit;
  }
  &__cover {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    height: 100%;
    transform: rotate(90deg);
    width: 100vh;
    z-index: 10;
  }

  &__container {
    position: absolute;
    top: 60px;
    width: 360px;
    left: 60px;

    &__header {
      @extend .sso_font--xbold;
      @extend .sso_font--2xlarge;
      display: flex;
      align-items: center;
      color: map-get($primary-colors, 'white');
      margin-bottom: $spacing_5xlarge;
    }

    &__description {
      @extend .sso_font;
      @extend .sso_font--small;
      color: map-get($primary-colors, 'white');
      margin-bottom: $spacing_2xlarge;
      width: 40ch;
    }

    &__redirect {
      &__link {
        @extend .sso_font;
        @extend .sso_font--bold;
        @extend .sso_font--small;
        color: map-get($primary-colors, 'white');
        text-decoration: none;

        &:hover > svg {
          animation: wiggle 1s both infinite;
        }
      }

      &__icon {
        position: relative;
        fill: map-get($primary-colors, 'white');
        top: 6px;
      }
    }
  }
}
