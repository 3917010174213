@import 'src/styles/variables';

$MAIN_CLASS: 'Preview';

.#{$MAIN_CLASS} {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid map-get($neutral-colors, '200');
  padding: $spacing_5xlarge $spacing_7xlarge;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: map-get($primary-colors, 'black');
  .rjsf {
    button {
      height: 40px;
      border-radius: 24px;
      border: none;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
      background-color: #ffffff;
      position: relative;
      width: max-content;
      cursor: pointer;
      box-shadow: inset 0 0 0 1px #0851df;
      color: #0851df;
      padding: 12px;
      margin-left: 14px;
      font-weight: bold;
    }
    .form-group {
      display: flex;
      flex-direction: column;
      justify-content: center;
      fieldset {
        border: 0;
        font-size: 14px;
        line-height: 18px;
        color: map-get($neutral-colors, '700');
      }
      legend {
        font-weight: bold;
        color: map-get($primary-colors, 'black');
        font-size: 16px;
        line-height: 20px;
        margin: 0 0 $spacing_large 0;
      }
      .field-description {
        font-weight: 700;
        color: map-get($primary-colors, 'black');
      }
      .field {
        margin-bottom: $spacing_medium;
        width: 50%;
        label {
          color: map-get($primary-colors, 'black');
          margin-bottom: $spacing_small;
        }
        input,
        textarea {
          min-height: 34px;
          border-radius: 5px;
          padding: 0 $spacing_medium;
          color: map-get($neutral-colors, '700');
          border: 1px solid map-get($neutral-colors, '300');
        }
      }
    }
  }
  &-error {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 360px;
    p {
      font-size: 14px;
      line-height: 18px;
      color: map-get($neutral-colors, '700');
    }
    svg {
      max-width: 200px;
      height: auto;
    }
  }
  > section {
    margin-bottom: $spacing_5xlarge;
    > h5 {
      font-weight: bold;
      color: map-get($primary-colors, 'black');
      font-size: 16px;
      line-height: 20px;
      margin: 0 0 $spacing_large 0;
      display: flex;
      align-items: center;
    }
    > div {
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      display: flex;
      align-items: center;
      padding: $spacing_medium 0;
    }
    .help {
      svg {
        width: 16px;
        height: 16px;
        margin-left: $spacing_small;
        transform: translateY(2.5px);
        path {
          fill: map-get($neutral-colors, '400');
        }
      }
    }
  }
}
img[id^='__lpform_cc-number_icon'] {
  display: none !important;
}

input {
  background-image: none !important;
}
[id^='__lpform_'] {
  display: none !important;
}
