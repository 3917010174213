@import 'src/styles/variables';
@import 'src/styles/fonts';
@import 'src/styles/mixins';
@import 'src/styles/animations';

.organizationList {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: $spacing_7xlarge;
    width: min-content;
  }
}
