@import 'src/styles/variables';
@import 'src/styles/fonts';
@import 'src/styles/mixins';
@import 'src/styles/animations';

$MAIN_CLASS: 'org_details';
$CONTENT_CLASS: $MAIN_CLASS + '__content';
$FORM_CLASS: $MAIN_CLASS + '__form';
$ITEM_CLASS: $FORM_CLASS + '__item';
$LOADING_CLASS: $MAIN_CLASS + '__loading';

.#{$MAIN_CLASS} {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: $spacing_7xlarge * 2;

  &__icon {
    padding: $spacing_7xlarge 0 0;
  }
}

.#{$CONTENT_CLASS} {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__delete_org_wrapper {
    padding-bottom: $spacing_7xlarge * 2;
    border-top: 1px solid map-get($neutral-colors, '200');
  }
  &__leave_org_wrapper {
    padding-bottom: $spacing_7xlarge;
  }
  &__delete_org_wrapper,
  &__leave_org_wrapper {
    p {
      font-size: 14px;
      line-height: 18px;
      margin: 1rem 0 2rem;
    }
  }
  &__wrapper {
    margin-bottom: 40px;
    max-width: 760px;
    width: 100%;
    display: flex;
    flex-direction: column;

    h2 {
      margin: 3rem 0 0;
      font-size: 1em;
    }

    h4 {
      margin: 3rem 0 0;
    }
  }
  &__avatar {
    width: 80px;
    height: 80px;
    font-size: 2rem;
    &-wrapper {
      width: 100%;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: map-get($neutral-colors, '400');
      display: flex;
      align-items: center;
      > p {
        margin-left: $spacing_5xlarge;
      }
    }
  }
  &__infos {
    &-wrapper {
      width: 100%;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: map-get($neutral-colors, '600');
      display: flex;
      flex-direction: column;
      border: 1px solid map-get($neutral-colors, '200');
      border-bottom: 0;
      margin-top: $spacing_7xlarge;
      &.list {
        margin-top: $spacing_2xlarge;
        p {
          margin-top: 0;
          margin-bottom: $spacing_small;
          strong {
            color: map-get($blues, '500');
          }
        }
      }
    }
    &-item {
      display: flex;
      border-bottom: 1px solid map-get($neutral-colors, '200');
      padding: 20px 12px;
      &-left {
        width: 40%;
      }
      &-right {
        color: map-get($primary-colors, 'black');
        text-transform: capitalize;
        min-width: 50%;
        position: relative;
        .verified {
          color: #10b2fb;
          display: flex;
          align-items: center;
          svg {
            margin-right: 6px;
            fill: #10b2fb;
          }
        }
        &-small {
          color: map-get($neutral-colors, '700');
          text-transform: none;
          margin-top: 1rem;
          font-size: 12px;
          line-height: 15px;
          a {
            color: map-get($blues, '500');
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  &__heading-text {
    @extend .sso_font;
    @extend .sso_font--xlarge;
    @extend .sso_font--bold;
    margin-top: $spacing_7xlarge;
    margin-bottom: $spacing_7xlarge;
    display: flex;
    flex-direction: column;
  }
  &__button.flight-button--link {
    font-weight: normal;
    span {
      padding: 0;
    }
  }
  &__button_delete.flight-button--primary {
    color: #fff;
    background-color: map-get($reds, '400');
    &:hover:enabled,
    &:focus:enabled {
      background-color: map-get($reds, '500');
    }
  }
  &__button_manage.flight-button--link {
    color: map-get($blues, '500');
    float: right;
    font-weight: normal;
    span {
      padding: 0;
    }
    &:hover:enabled,
    &:focus:enabled {
      color: map-get($blues, '500');
      span {
        color: map-get($blues, '500');
      }
    }
  }
  &__button_link.flight-button--link {
    font-weight: normal;
    color: map-get($blues, '500');
    align-self: flex-start;
    margin-bottom: 16px;
    span {
      padding: 0;
    }
    &.small {
      font-size: 12px;
      line-height: 15px;
      span {
        padding: 8px 12px;
      }
    }
    color: map-get($blues, '500');
    &:hover:enabled,
    &:focus:enabled {
      color: map-get($blues, '500');
      span {
        color: map-get($blues, '500');
      }
    }
    &-back {
      align-self: flex-start;
      margin-bottom: $spacing_xlarge;
    }
  }
  &__modal {
    max-width: 480px !important;
  }
  &__input {
    margin-top: $spacing_2xlarge;
    &__inline {
      margin-right: $spacing_large;
    }
  }
  &__row {
    margin-top: $spacing_5xlarge;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-end;
  }
  &__signup {
    margin-top: 80px;
    float: right;
    padding: $spacing_medium $spacing_2xlarge;
    margin-right: 12px;
  }
  &__link {
    margin-top: 80px;
    padding: $spacing_medium 0;
    @extend .sso_font--small;
    text-decoration: none;
    &:active,
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  &__password-sent {
    display: flex;
    align-items: center;
    flex-direction: column;
    p {
      text-align: center;
      margin-top: 0;
      margin-bottom: $spacing_5xlarge;
    }
    h3 {
      font-weight: bold;
      font-size: 20px;
      line-height: 25px;
    }
  }
  form {
    position: relative;
  }
  &__info {
    width: fit-content;
  }
}
.#{$ITEM_CLASS} {
  &__hide {
    display: none;
    visibility: hidden;
  }
  &__group {
    display: flex;
    flex-wrap: wrap;
  }
  &__remove {
    color: map-get($reds, '500') !important;
    padding-top: 10px;
  }
  &__image-field {
    display: flex;
    width: 80px;
    height: 80px;
    /* N200 */
    border: 1px solid transparent;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    flex: 0 0 80px;
    margin-right: 20px;
    transition: all 0.2s linear;

    .actions {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      left: 0;
      top: 0;
      width: 80px;
      height: 80px;
      z-index: 11;
      opacity: 0;
      transition: opacity 0.2s linear;
      background-color: rgba(255, 255, 255, 0.8);
      .flight-button--link--has-underline:hover:enabled,
      .flight-button--link--has-underline:focus:enabled {
        outline: auto;
        outline-offset: -5px;
        height: 70px;
      }
    }
    &:hover,
    &:focus,
    &:focus-within,
    &:active {
      border-color: map-get($blues, '500');
      .actions {
        opacity: 1;
      }
    }
    &:focus-within {
      outline: auto;
    }
    .flight-text-input-wrapper {
      position: relative;
      .organizations__form__item__file {
        input {
          position: absolute;
          left: 0;
          top: 0;
          width: 0;
          height: 0;
          margin: 0;
          padding: 0;
        }
      }
      &:focus-within {
        outline: auto;
      }
    }
  }
  width: 100%;
  min-width: 300px;
  display: flex;
  &__button {
    @extend .#{$ITEM_CLASS};
    margin-top: $spacing_5xlarge;
    margin-bottom: $spacing_2xlarge;
  }
  &__edit {
    position: relative;
    width: 100%;

    &:focus-within {
      svg,
      label {
        opacity: 0;
        pointer-events: none;
        transition: all 0.1s linear;
      }
    }
    label {
      position: absolute;
      pointer-events: none;
      font-weight: 800;
      font-size: 24px;
      line-height: 30px;
      border: 0;
      padding-top: 4px;
      padding-inline-start: 2.5px;
      color: transparent;
      opacity: 0;
      transition: opacity 0.2s linear;
      &.loaded {
        opacity: 1;
      }
    }
    &-input {
      font-weight: 800;
      font-size: 20px;
      line-height: 25px;
      font-family: 'Muli', sans-serif;
      border: 0;
      padding-top: 5px;
      border-bottom: 2px solid transparent;
      transition: all 0.2s ease;
      width: 100%;
      display: none;
      &.loaded {
        display: block;
      }
      &:-internal-autofill-selected,
      &:-webkit-autofill {
        background-color: transparent !important;
        appearance: none;
        box-shadow: 0 0 0 30px white inset;
        -webkit-box-shadow: 0 0 0 30px white inset;
        border-bottom: 2px solid #fff;
      }
      &:focus {
        border-bottom: 2px solid map-get($blues, '500');
        outline: none;
        width: 100%;
        padding-inline-start: 5px;
      }
      &:disabled {
        background-color: transparent;
      }
    }
    p {
      color: map-get($neutral-colors, '700');
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      margin-top: 6px;
      margin-bottom: 1rem;
    }
  }
  &__input {
    margin-top: $spacing_2xlarge;
    &__inline {
      margin-right: $spacing_large;
    }
  }
  &__row {
    @extend .#{$ITEM_CLASS};
    margin-top: $spacing_5xlarge;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-end;
  }
}

.loading__owners {
  width: 100%;
  margin-top: $spacing_7xlarge;
}

.modal_buttons {
  text-align: right;
  button {
    margin-left: 8px;
  }
}

.#{$LOADING_CLASS} {
  width: 100%;
  text-align: center;

  svg:last-child {
    fill: map-get($reds, '500');
  }
}
