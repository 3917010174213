@import 'src/styles/variables';

.json-editor {
  display: flex;
  flex-direction: column;
  .editors {
    display: flex;
    flex-direction: row;
    gap: 20px;
    .panel-heading {
      margin-block-start: 0;
      font-size: 14px;
      display: flex;
      align-items: center;
      svg {
        width: 14px;
        height: 14px;
        margin-right: 6px;
      }
    }
    .panel-editor {
      height: 296px;
      overflow: auto;
      border: 1px solid #ced4da;
    }
  }
  .editor {
    counter-reset: line;

    &-schema {
      width: 55%;
    }
    &-ui {
      width: 45%;
    }
  }

  .editor #codeArea {
    outline: none;
    padding-left: 40px !important;
  }

  .editor pre {
    padding-left: 40px !important;
  }

  .editor .editorLineNumber {
    position: absolute;
    left: 0px;
    color: map-get($neutral-colors, '500');
    text-align: center;
    width: 30px;
    font-weight: 100;
    background-color: map-get($neutral-colors, '50');
  }
  &__editor {
    display: flex;
    box-sizing: border-box;
    height: 100%;
    position: relative;
  }

  &__line-col {
    display: flex;
    background-color: map-get($neutral-colors, '50');
    border: 1px solid map-get($neutral-colors, '200');
    border-top: none;
    padding: $spacing_small $spacing_medium;
    justify-content: space-between;
    font-size: 10px;
    line-height: 14px;
    align-items: center;
    margin-bottom: 0;
    color: map-get($neutral-colors, '500');
    min-height: 14px;
    &.error {
      color: map-get($reds, '700');
      background-color: map-get($reds, '50');
    }
    .validation-icon {
      display: flex;
      align-items: center;
      svg {
        width: 14px;
        height: 14px;
        &:not(:first-child) {
          margin-left: 5px;
        }
      }
      > * {
        animation: fadein 0.3s ease-in 0s 1 forwards;
        opacity: 0;
      }
    }
  }
  &__actions {
    display: flex;
    position: absolute;
    bottom: $spacing_large;
    right: $spacing_xlarge;
    button {
      margin-left: $spacing_medium;
      font-size: 12px;
      line-height: 15px;
      background-color: #fff;
      color: map-get($neutral-colors, '700');
      border: 1px solid map-get($neutral-colors, '300');
      box-sizing: border-box;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
      font-weight: bold !important;
      padding: 6px $spacing_small;
      svg {
        width: 14px;
        height: 14px;
        path {
          fill: map-get($neutral-colors, '700');
        }
      }
      &:hover,
      &:focus {
        text-decoration: none !important;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
        color: map-get($blues, '500');
        svg > path {
          fill: map-get($blues, '500');
        }
      }
      &:first-child {
        margin-left: auto;
      }
    }
  }
  &__line-numbers {
    display: flex;
    flex-direction: column;
    background-color: map-get($neutral-colors, '50');
    border: 1px solid map-get($neutral-colors, '300');
    border-right: none;
    max-height: 360px;
    overflow: hidden;
    padding: 2px 0;
    position: relative;
    z-index: 20;
  }

  &__line-number {
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    padding: 0px 10px 0 20px;
    line-height: 19px;
    background-color: map-get($neutral-colors, '100');
    border-bottom: 1px solid map-get($neutral-colors, '200');
    position: relative;
    &.line-error {
      background-color: map-get($reds, '100');
      border-bottom: 1px solid map-get($reds, '200');
    }
  }

  &__text-area {
    resize: none;
    width: 100%;
    height: 280px;
    overflow: auto;
    max-height: 280px;
    border: 1px solid map-get($neutral-colors, '300');
    border-left: none;
    outline: none;
    color: map-get($neutral-colors, '700');
    textarea {
      overflow: auto;
    }

    &.error {
      outline: 2px solid map-get($reds, '400');
      outline-offset: -5px;
      &::selection {
        color: #fff;
        background-color: map-get($reds, '400');
      }
    }
  }

  &__error-icon {
    fill: map-get($reds, '500');
  }

  &__error-message {
    font-size: 12px;
    line-height: 1.2;
    color: map-get($primary-colors, 'black');
    margin: 0 0 $spacing_large;
    padding: $spacing_large;
    background-color: map-get($reds, '50');
    display: flex;
    align-items: center;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
