@import 'src/styles/variables';
@import 'src/styles/fonts';
@import 'src/styles/animations';

$MAIN_CLASS: 'Remapping';
.code {
  margin-left: auto;
  background-color: map-get($neutral-colors, '100');
  color: map-get($neutral-colors, '500');
  border-radius: 3px;
  border: 1px solid map-get($neutral-colors, '300');
  padding: 0 4px;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  font-size: 9px;
  height: 14px;
  justify-content: center;
}
.has-parameters:before {
  content: 'P';
  width: 12px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e59e03;
  color: #fff;
  font-size: 7px;
  right: -12px;
  top: 0px;
  font-weight: bold;
  border-radius: 8px;
  position: absolute;
}
.#{$MAIN_CLASS} {
  width: 100%;
  height: 100%;
  padding-bottom: $spacing_7xlarge * 2;
  position: relative;
  &__select-project {
    width: 100%;
    box-shadow: 0 1px 0 map-get($neutral-colors, '200');
    padding: 12px 0;
    display: flex;
    align-items: center;
    top: 0;
    .flight-select-searchable {
      .flight-text-input-wrapper {
        width: 360px !important;
      }
    }
    .progress {
      margin-left: auto;
      strong {
        color: map-get($blues, '500');
        font-size: 14px;
      }
    }
    .flight-select__dropdown {
      background-color: transparent;
      border: 0;
      &:focus,
      &:focus-within {
        outline: auto;
      }
      .flight-button {
        justify-content: flex-start;
        text-align: left;
        &__label {
          color: map-get($neutral-colors, '900');
          font-weight: bold;
          line-height: 1;
        }
        > svg {
          fill: map-get($neutral-colors, '900');
        }
      }
    }
    .project-icon {
      svg {
        width: 36px;
        height: 36px;
      }
    }
  }
  &__datasources {
    .items {
      list-style: none;
      display: flex;
      flex-direction: column;
      margin-top: 24px;
      li {
        display: flex;
        gap: 12px;
        padding: 16px;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        box-shadow: 0px 0px 0px 1px inset map-get($neutral-colors, '200');
        cursor: pointer;
        &.active,
        &:hover,
        &:focus,
        &:focus-within {
          box-shadow: 0px 0px 0px 1px inset map-get($blues, '400');
          background-color: map-get($blues, '50');
        }
        .item {
          &-image {
            height: 32px;
            width: 32px;
            img {
              height: 32px;
              width: 32px;
              object-fit: cover;
            }
            svg {
              width: 32px;
              height: 32px;
            }
          }
          &-content {
            color: map-get($neutral-colors, '900');
          }
        }
      }
    }
    .attributes {
      display: flex;
      flex-direction: column;
      margin-top: 24px;
      box-shadow: 0px 0px 0px 1px inset map-get($neutral-colors, '200');
      height: 100%;
      .titles {
        font-weight: 600;
        color: map-get($neutral-colors, '600');
        font-size: 14px;
        display: flex;
        justify-content: space-between;
        box-shadow: 0px -1px 0px 0px inset map-get($neutral-colors, '200');
        padding: 12px;
      }
      .fields {
        padding: 12px;
        height: 100%;
        .field {
          display: flex;
          align-items: center;
          font-size: 12px;
          padding: 6px 0;
          cursor: grab;
          box-shadow: 0px -1px 0px 0px inset map-get($neutral-colors, '100');
          opacity: 0.7;
          gap: 8px;
          position: relative;
          &:hover,
          .active,
          &:focus,
          &:focus-within {
            background-color: #f0fbff;
            opacity: 1;
          }
          .handle {
            color: map-get($neutral-colors, '200');
            font-weight: bold;
            transform: rotate(90deg);
          }
          .name {
            position: relative;
          }
        }
      }
    }
  }
  &__content {
    &-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 24px;
    }
    &-column {
      display: flex;
      flex-direction: column;
      width: 50%;
      &.empty {
        width: 90%;
        margin-top: 24px;
        h2 {
          font-weight: 800;
        }
        p {
          font-size: 14px;
          line-height: 1.3;
          margin-bottom: $spacing_7xlarge;
        }
      }
      h3 {
        font-size: 16px;
      }
    }

    .datasource,
    .context-plugins {
      width: 50%;
    }
    .context-plugins {
      &-empty {
        margin-top: 24px;
        h2 {
          font-weight: 800;
        }
        p {
          font-size: 14px;
          line-height: 1.3;
          margin-bottom: $spacing_7xlarge;
        }
      }
      .plugins {
        margin-top: 24px;
        height: calc(100vh - 450px);
        overflow: auto;
        details {
          background: #fff;
          box-shadow: 0px 0px 0px 1px inset map-get($neutral-colors, '200');
          &:hover,
          &:focus,
          &:active,
          &:focus-within {
            box-shadow: 0px 0px 0px 1px inset map-get($blues, '100');
          }
          summary {
            box-sizing: border-box;
            list-style: none;
            transition: all 250ms ease;
            background: map-get($neutral-colors, '50');
            border: 1px solid map-get($neutral-colors, '300');
            color: map-get($primary-colors, 'black');
            font-size: 14px;
            font-weight: bold;
            padding: 12px;
            cursor: pointer;
            position: relative;
            transition: margin 250ms ease-out;
            width: 100%;
            .options {
              position: absolute;
              right: 36px;
              .flight-overflow-menu {
                button {
                  background-color: transparent;
                  padding: 0;
                  margin: 0;
                  height: auto;
                  svg {
                    fill: map-get($primary-colors, 'black');
                  }
                }
              }
            }
          }
          summary::-webkit-details-marker {
            display: none;
          }
        }

        details[open] summary {
          transition: all 250ms ease;
          &:hover,
          &:focus,
          &:active,
          &:focus-within {
            &:not(:has(.error)) {
              background-color: #e3efff;
              border-color: map-get($blues, '300');
            }
          }
          .plugin-top-arrow {
            transform: rotate(180deg);
          }
        }
        .plugin {
          position: relative;
          box-sizing: border-box;
          margin-bottom: 16px;
          &-top-arrow {
            position: absolute;
            right: 12px;
            transform-origin: 12px 12px;
            transition: transform 200ms ease-in-out;
          }
          &-name {
            font-size: 12px;
            font-weight: 600;
            color: map-get($neutral-colors, '700');
            height: 100%;
            width: 20%;
            padding-right: 24px;
            box-shadow: 1px 0px 0px 0px map-get($neutral-colors, '200');
          }
          &-mapping {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            height: 100%;
            padding-left: 24px;
            width: 100%;
            position: relative;
            &.has-parameters:before {
              left: 98px;
              top: auto;
            }

            > span {
              text-transform: uppercase;
              color: map-get($neutral-colors, '400');
              font-weight: bold;
              font-size: 10px;
            }
          }
          &-card {
            box-sizing: border-box;
            border: 1px solid map-get($neutral-colors, '200');
            display: flex;
            border-radius: 5px;
            padding: 8px;
            align-items: center;
            width: 100%;
            gap: 12px;
            color: map-get($neutral-colors, '700');
            background-color: map-get($neutral-colors, '50');
            &.filled {
              background-color: #e3efff !important;
              border-color: map-get($blues, '300') !important;
              opacity: 1 !important;
            }
            .code {
              background-color: #fcfcfc;
            }
            &-actions {
              box-shadow: -1px 0px 0px 0px map-get($neutral-colors, '200');
              padding-left: 4px;
              .flight-button {
                padding: 0;
                background-color: transparent;
                width: 24px;
              }
            }
            &-content {
              .parent {
                font-size: 11px;
                display: flex;
                gap: 4px;
                > svg {
                  flex: 16px 0;
                }
                &-icon {
                  width: 16px;
                  height: 16px;
                }
              }
              > span {
                font-weight: bold;
                font-size: 12px;
              }
            }
            &.empty {
              border-style: dashed;
              color: map-get($neutral-colors, '400');
            }
          }
          &-field {
            padding: 12px;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            font-size: 14px;
            justify-content: space-between;
            &:not(:last-child) {
              box-shadow: 0px -1px 0px 0px inset map-get($neutral-colors, '100');
            }
          }
        }
      }
    }
    &-empty {
      display: flex;
      flex-direction: row;
      align-items: center;
      svg {
        width: 100%;
        height: auto;
        padding-left: 50px;
        margin-top: 24px;
      }
    }
  }
  &__parameter-remapping {
    &-footer {
      gap: $spacing_large;
      display: flex;
      justify-content: flex-end;
      .infos {
        margin-right: auto;
        font-size: 12px;
        display: flex;
        gap: 6px;
        align-items: center;
        &.warning {
          color: #e59e03;
        }
        &.error {
          color: map-get($reds, '500');
        }
      }
    }
    .remove-icon {
      cursor: pointer;
    }
    &-content {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .arrow-body {
        opacity: 0.5;
        transition: opacity 0.2s ease;
        &:hover {
          opacity: 1;
        }
      }
      .column {
        width: 40%;
        margin: $spacing_xlarge 0;
        > small {
          margin: $spacing_xlarge 0;
          display: block;
        }
        &.destination {
          .params,
          strong {
            margin-left: auto;
          }
          > small {
            margin-left: auto;
            width: 90%;
          }
        }
        > strong {
          display: flex;
          width: 90%;
        }
        .params {
          display: flex;
          flex-direction: column;
          width: 90%;
          .param {
            cursor: grab;
            display: flex;
            margin-top: $spacing_xlarge;
            padding: 10px 16px;
            align-items: center;
            box-sizing: border-box;
            gap: 10px;
            flex-shrink: 0;
            border-radius: 5px;
            border: 1px solid map-get($neutral-colors, '200');
            justify-content: flex-start;
            &.error {
              border: 1px solid map-get($reds, '500') !important;
            }
            &.extra-field {
              display: flex;
              > div {
                margin-top: $spacing_medium;
                display: none;
              }
              summary {
                gap: 10px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                .plugin-top-arrow {
                  margin-left: auto;
                  padding: 0;
                  display: flex;
                  svg {
                    width: 18px;
                    height: 18px;
                  }
                }
              }
              &[open] {
                summary {
                  .plugin-top-arrow {
                    transform: rotate(180deg);
                  }
                }
                > div {
                  display: block;
                }
              }
            }
            .code {
              margin-left: 0;
            }
            div {
              font-weight: 600;
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}
