@import '../variables';
@import '../fonts';
$MAIN_CLASS: 'signup';
$FORM_CLASS: $MAIN_CLASS + '__form';
$ITEM_CLASS: $FORM_CLASS + '__item';

$image_height: 40px;

.#{$MAIN_CLASS} {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  &__logo {
    width: auto;
    height: $image_height;
    position: relative;
    right: 18px;
  }
  &__heading-text {
    @extend .sso_font;
    @extend .sso_font--xlarge;
    @extend .sso_font--bold;
    margin-top: $spacing_7xlarge;
    margin-bottom: $spacing_xlarge;
  }
  &__reset-snackbar {
    bottom: 10%;
  }
}

.#{$FORM_CLASS} {
  width: 50vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.#{$ITEM_CLASS} {
  width: 50%;
  display: flex;
  &__checkbox-error-container {
    margin-left: 5%;
    color: $red-500;
    font-size: 12px;
  }
  &__button {
    @extend .#{$ITEM_CLASS};
    margin-top: $spacing_5xlarge;
    margin-bottom: $spacing_2xlarge;
    width: 50% !important;
  }
  &__checkbox {
    display: flex;
    align-items: center;
    .flight-checkbox-wrapper {
      &:focus {
        .flight-checkbox {
          outline: auto;
        }
      }
    }
  }
  &__checkbox-container {
    margin-top: -15px;
  }
  &__signin {
    @extend .sso_font--small;
    margin-top: $spacing_2xlarge;
    a {
      color: #0851df;
    }
  }
  &__input {
    margin-top: $spacing_2xlarge;
    &__inline {
      margin-right: $spacing_large;
    }
  }
  &__row {
    @extend .#{$ITEM_CLASS};
    margin-top: $spacing_5xlarge;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-end;
  }
  &__link {
    @extend .sso_font--small;
    text-decoration: none;
    &:active,
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  &__agreement {
    @extend .sso_font--small;
  }
  &__signup-confirmation {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__confirmation-text {
    display: flex;
    flex-direction: column;
  }
  &__link-confirmation-redirect {
    display: flex;
    align-items: center;
    svg {
      width: 18px;
      fill: #0851df;
    }
  }
}
