@import 'variables';
@import 'fonts';
@import 'mixins';
@import 'forms';

body {
  margin: 0;
  height: 100vh;
  overflow: hidden;
  font-family: 'Muli', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.isDragging * {
    cursor: grabbing !important;
    @at-root #{&} {
      cursor: grabbing !important;
    }
  }
  > h1 {
    display: none;
  }
}
:global {
  body.isDragging,
  body.isDragging * {
    cursor: grabbing !important;
  }
}

#root,
.application {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
// Design system overrides
.flight-modal-wrapper {
  z-index: 100 !important;
}
.flight-button--link {
  font-weight: normal !important;
}
// Fix autofilled labels for webkit browsers
.flight-text-input__input:-webkit-autofill ~ .flight-text-input__label {
  transform: translateY(-8px) scale(0.85);
}
