@import 'src/styles/variables';
@import 'src/styles/fonts';
@import 'src/styles/mixins';

$MAIN_CLASS: 'CollapsibleMultiple';
$CONTENT_CLASS: $MAIN_CLASS + '__content';
$LABEL_CLASS: $MAIN_CLASS + '__label';
$HEADER_CLASS: $MAIN_CLASS + '__header';

.#{$MAIN_CLASS} {
  &-wrapper {
    height: auto;
    width: 100%;
    counter-reset: step;
  }
  &--disabled {
    cursor: no-drop;
    .CollapsibleMultiple__label {
      pointer-events: none;
      .CollapsibleMultiple__header {
        * {
          color: map-get($neutral-colors, '300') !important;
        }
      }
    }
  }
}
.#{$LABEL_CLASS} {
  padding: $spacing_xlarge $spacing_5xlarge;
  box-shadow: 0 1px 0 map-get($neutral-colors, '200');
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover,
  &:focus {
    color: map-get($primary-colors, 'black');

    &--selected {
      @include prefix-property(box-shadow, none);
    }
  }

  &:hover,
  &:focus {
    background-color: rgba(242, 243, 245, 0.7);
    cursor: pointer;
  }
  &:focus {
    outline: auto;
  }

  &__icon {
    position: relative;
    fill: map-get($blues, '500');
    float: right;
  }

  &--disabled {
    color: map-get($neutral-colors, '300') !important;

    &:hover,
    &:focus {
      color: map-get($neutral-colors, '300') !important;
    }

    .CollapsibleMultiple__label__icon {
      fill: map-get($neutral-colors, '300') !important;
    }
  }
}

.#{$CONTENT_CLASS} {
  background-color: rgba(0, 0, 0, 0.025);
  transition: height 300ms;
  box-shadow: 0 1px 0 map-get($neutral-colors, '200');
  &--selected {
    min-height: 50px;
  }
}
div[aria-checked='true'] > .CollapsibleMultiple__header > div:last-child {
  font-weight: bold;
}
.#{$HEADER_CLASS} {
  display: flex;
  align-items: center;
  gap: 0 $spacing_2xlarge;
  width: 100%;
  &:focus {
    outline: auto;
  }
  .title {
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    color: map-get($primary-colors, 'black');
    margin: 0 0 2px;
  }
  .zero {
    color: map-get($neutral-colors, '600') !important;
    font-weight: normal !important;
  }
  .infos {
    width: 100%;
  }
  .info {
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: map-get($neutral-colors, '700');
    max-width: 60%;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  > div:last-child {
    font-size: 14px;
    line-height: 18px;
    color: map-get($blues, '500');
    text-align: right;
    width: 120px;
  }
}
