@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/fonts';
@import '../../styles/animations';

.organizationListItem {
  &__wrapper {
    margin-bottom: $spacing_xlarge;
    display: flex;
    width: 100%;
    justify-content: center;
  }

  &__border.flight-card {
    // @include outline-none;
    cursor: pointer;
    background-color: map-get($primary-colors, 'white');
    border-radius: 10px;
    border: $border-width solid $border-color;
    box-sizing: border-box;
    width: 440px;
    min-width: 320px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center;
    box-shadow: none;

    .flight-card {
      @extend .sso_font;

      height: 100%;
      &__image {
        width: 80px;
        max-width: 80px;
        img {
          font-size: 0;
        }
      }
      &__header {
        display: flex;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-right: auto;
        flex-wrap: wrap;

        svg {
          margin-left: 4px;
        }
        .project__id {
          @extend .sso_font;
          @extend .sso_font--small;
          display: flex;
          width: 100%;
        }
      }
      &__actionables {
        display: flex;
        top: unset;
        right: unset;
        position: relative;
        .card__icon {
          margin: 0 30px;
          > svg {
            animation-play-state: paused;
            transform-origin: center;
            transform: translateX(0);

            position: relative;
            fill: map-get($blues, '500');
          }
        }
      }
    }
    &:hover {
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
      .flight-card {
        &__actionables {
          .card__icon {
            > svg {
              animation: wiggle 1s both infinite;
            }
          }
        }
      }
    }
  }

  &__project-icon {
    margin-left: 20px;
    margin-right: 20px;
  }
}
