@import 'src/styles/variables';
@import 'src/styles/fonts';
@import 'src/styles/animations';

$MAIN_CLASS: 'Authorization';

.#{$MAIN_CLASS} {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: $spacing_7xlarge * 2;
}

.section {
  &__fields {
    max-width: 600px;
    &-field {
      &.status {
        margin-top: $spacing_large;
        margin-bottom: $spacing_2xlarge;
        > div {
          color: map-get($primary-colors, 'black');
          font-weight: normal;
          font-size: 14px;
          line-height: 18px;
          display: flex;
          align-items: center;
          svg {
            margin-right: $spacing_medium;
            width: 24px;
            height: 24px;
          }
        }
        .error {
          padding: $spacing_medium;
          background-color: map-get($reds, '50');
        }
        .success {
          > span {
            margin-left: auto;
            font-size: 12px;
            line-height: 15px;
          }
        }
      }
      &.readonly {
        background-color: map-get($neutral-colors, '50');
        font-size: 12px;
        line-height: 15px;
        margin-top: 0;
        color: map-get($neutral-colors, '700');
        padding: $spacing_large;
        strong {
          margin-right: $spacing_large;
        }
      }
    }
    &.authorized {
      padding: $spacing_2xlarge;
      border: 1px solid map-get($neutral-colors, '300');
      margin-top: $spacing_large;
      .status {
        margin-top: 0;
      }
      div {
        &:last-child {
          margin-bottom: $spacing_small;
        }
      }
    }
  }
}
