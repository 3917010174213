@import 'src/styles/variables';

.change-name-modal {
  &__content {
    &__input {
      margin-top: $spacing_2xlarge;
    }
    &__submit {
      margin-top: 40px;
      float: right;
      padding: $spacing_medium $spacing_2xlarge;
      margin-right: 12px;
    }
  }
}
