@import 'src/styles/variables';
@import 'src/styles/fonts';
@import 'src/styles/animations';

$MAIN_CLASS: 'verify';
$CONTENT_CLASS: $MAIN_CLASS + '__content';
$ITEM_CLASS: $CONTENT_CLASS + '__item';

.#{$MAIN_CLASS} {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__icon {
    padding: $spacing_7xlarge 0 0;
  }
}

.#{$CONTENT_CLASS} {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__wrapper {
    margin-top: 40px;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__heading-text {
    @extend .sso_font;
    @extend .sso_font--xlarge;
    @extend .sso_font--xbold;
    margin-top: $spacing_7xlarge;
    margin-bottom: $spacing_xlarge;
  }
  &__description-text {
    @extend .sso_font;
    @extend .sso_font--small;
    text-align: center;
    // color: map-get($neutral-colors, '700');
    color: map-get($primary-colors, 'black');
    margin-top: 24px;
  }
  &__verification-sent {
    @extend .sso_font;
    @extend .sso_font--small;
    display: flex;
    align-items: center;
    justify-content: center;
    color: map-get($greens, '600');
    opacity: 1 !important;
    svg {
      padding-right: $spacing_medium;
    }
  }
  &__button {
    margin-top: $spacing_5xlarge;
    margin-bottom: $spacing_2xlarge;
    padding-inline-start: $spacing_7xlarge;
    padding-right: $spacing_7xlarge;
    margin-left: $spacing_medium;
    margin-right: $spacing_medium;
  }
}
