@import 'src/styles/variables';

.input-preview {
  > label {
    height: 24px;
    margin: 1em 0;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    color: map-get($primary-colors, 'black');
    display: flex;
    align-items: center;
    column-gap: 5px;
  }
}
