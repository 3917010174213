@import 'src/styles/variables';
@import 'src/styles/fonts';
@import 'src/styles/mixins';
@import 'src/styles/animations';

$MAIN_CLASS: 'DataSourceCreate';
$CONTENT_CLASS: $MAIN_CLASS + '__content';
$FORM_CLASS: $MAIN_CLASS + '__form';
$ITEM_CLASS: $FORM_CLASS + '__item';
$LOADING_CLASS: $MAIN_CLASS + '__loading';

.#{$MAIN_CLASS} {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: $spacing_7xlarge * 2;

  &__icon {
    padding: $spacing_7xlarge 0 0;
  }
}
.connectorCardList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 5px 30px;
}
.connectorDeployments {
  display: flex;
  flex-direction: column;
  .deploymentCard {
    width: 600px;
    background: #ffffff;
    border: 1px solid map-get($neutral-colors, '200');
    box-sizing: border-box;
    border-radius: 10px;
    margin-bottom: $spacing_2xlarge;
    padding: 26px 20px;
    display: flex;
    align-items: center;
    transition: all 0.2s;
    cursor: pointer;
    &:hover,
    &:focus,
    &:focus-within {
      background-color: rgba(221, 236, 255, 0.5);
      border-color: map-get($blues, '400');
    }
    &__icon {
      margin-right: $spacing_2xlarge;
    }
    &__name {
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: map-get($primary-colors, 'black');
    }
    &__arrow {
      margin-left: auto;
      fill: map-get($blues, '500');
    }
  }
}
.#{$CONTENT_CLASS} {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__wrapper {
    margin-bottom: 40px;
    max-width: 1106px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: $spacing_7xlarge * 2;
    h4 {
      margin: 3rem 0 0;
    }
  }
  &__filters {
    display: flex;
    gap: 0 20px;
    margin: 0px 0 30px;
  }
  &__heading-text {
    @extend .sso_font;
    @extend .sso_font--xlarge;
    @extend .sso_font--bold;
    margin-top: $spacing_7xlarge;
    margin-bottom: $spacing_xlarge;
    display: flex;
    flex-direction: column;
  }
  &__heading-description {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: map-get($neutral-colors, '700');
    margin: 0px 0 30px;
  }
  &__button.flight-button--link {
    font-weight: normal;
    span {
      padding: 0;
    }
  }
  &__button_delete.flight-button--primary {
    color: #fff;
    background-color: map-get($reds, '400');
    &:hover:enabled,
    &:focus:enabled {
      background-color: map-get($reds, '500');
    }
  }
  &__button_manage.flight-button--link {
    color: map-get($blues, '500');
    float: right;
    font-weight: normal;
    span {
      padding: 0;
    }
    &:hover:enabled,
    &:focus:enabled {
      color: map-get($blues, '500');
      span {
        color: map-get($blues, '500');
      }
    }
  }
  &__button_link.flight-button--link {
    font-weight: normal;
    color: map-get($blues, '500');
    align-self: flex-start;
    margin-bottom: 16px;
    span {
      padding: 0;
    }
    &.small {
      font-size: 12px;
      line-height: 15px;
      span {
        padding: 8px 12px;
      }
    }
    color: map-get($blues, '500');
    &:hover:enabled,
    &:focus:enabled {
      color: map-get($blues, '500');
      span {
        color: map-get($blues, '500');
      }
    }
    &-back {
      align-self: flex-start;
      margin-bottom: $spacing_xlarge;
    }
  }
  &__modal {
    max-width: 480px !important;
  }
  &__input {
    margin-top: $spacing_2xlarge;
    &__inline {
      margin-right: $spacing_large;
    }
  }
  &__row {
    margin-top: $spacing_5xlarge;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-end;
  }
  &__signup {
    margin-top: 80px;
    float: right;
    padding: $spacing_medium $spacing_2xlarge;
    margin-right: 12px;
  }
  &__link {
    margin-top: 80px;
    padding: $spacing_medium 0;
    @extend .sso_font--small;
    text-decoration: none;
    &:active,
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  &__password-sent {
    display: flex;
    align-items: center;
    flex-direction: column;
    p {
      text-align: center;
      margin-top: 0;
      margin-bottom: $spacing_5xlarge;
    }
    h3 {
      font-weight: bold;
      font-size: 20px;
      line-height: 25px;
    }
  }
  form {
    position: relative;
  }
  &__info {
    width: fit-content;
  }
}
.#{$FORM_CLASS} {
  display: flex;
  flex-wrap: wrap;
  &__footer {
    display: flex;
    button {
      margin-right: $spacing_xlarge;
    }
  }
  &__right {
    width: 35%;
    height: 246px;
    border: 1px solid map-get($neutral-colors, '200');
    box-sizing: border-box;
    transform: translateY(-$spacing_7xlarge * 1.5);
    padding: $spacing_2xlarge * 1.4;
    .item {
      display: flex;
      margin-bottom: $spacing_xlarge;
      svg {
        width: 40px;
        height: 40px;
        margin-right: $spacing_2xlarge;
      }
      h2 {
        font-size: 16px;
        line-height: 20px;
        color: map-get($primary-colors, 'black');
        font-weight: normal;
        margin: 0;
      }
      p {
        font-size: 12px;
        line-height: 15px;
        color: map-get($neutral-colors, '700');
        padding: 0;
      }
      .group {
        display: flex;
        flex-direction: column;
        width: 265px;
      }
    }
  }
  &__left {
    width: 65%;
    h3 {
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: map-get($primary-colors, 'black');
    }
    > div {
      margin-bottom: $spacing_5xlarge;
      .flight-text-area {
        padding: 0 0 24px;
      }
    }
  }
}
.#{$ITEM_CLASS} {
  &__hide {
    display: none;
    visibility: hidden;
  }

  width: 100%;
  min-width: 300px;
  display: flex;
  &__button {
    @extend .#{$ITEM_CLASS};
    margin-top: $spacing_5xlarge;
    margin-bottom: $spacing_2xlarge;
  }

  &__input {
    margin-top: $spacing_2xlarge;
    &__inline {
      margin-right: $spacing_large;
    }
  }
  &__row {
    @extend .#{$ITEM_CLASS};
    margin-top: $spacing_5xlarge;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-end;
  }
}

.loading__owners {
  width: 100%;
  margin-top: $spacing_7xlarge;
}

.modal_buttons {
  text-align: right;
  button {
    margin-left: 8px;
  }
}

.#{$LOADING_CLASS} {
  width: 100%;
  text-align: center;

  svg:last-child {
    fill: map-get($reds, '500');
  }
}
