@mixin prefix-property($name, $value) {
  -webkit-#{$name}: $value;
  -moz-#{$name}: $value;
  -ms-#{$name}: $value;
  -o-#{$name}: $value;
  #{$name}: $value;
}

@mixin keyframe-animation($animation_name) {
  @-webkit-keyframes #{$animation_name} {
    @content;
  }

  @-moz-keyframes #{$animation_name} {
    @content;
  }

  @-o-keyframes #{$animation_name} {
    @content;
  }

  @keyframes #{$animation_name} {
    @content;
  }
}

@mixin animation(
  $animation,
  $delay,
  $duration,
  $timing_function,
  $iteration_count,
  $direction,
  $fill_mode,
  $play_state
) {
  @include prefix-property(animation-name, $animation);
  @include prefix-property(animation-delay, $delay); // default 0s
  @include prefix-property(animation-duration, $duration); // default 0s
  @include prefix-property(animation-timing-function, $timing_function); // default ease-in
  @include prefix-property(animation-iteration-count, $iteration_count); // default 1
  @include prefix-property(animation-direction, $direction); // default normal
  @include prefix-property(animation-fill-mode, $fill_mode); // default none
  @include prefix-property(animation-play-state, $play_state); // default running
}

@mixin outline-none {
  &,
  * {
    outline: none;
  }
}

@mixin border-box {
  &,
  * {
    box-sizing: border-box;
  }
}

@mixin outline-shadow {
  outline: none;
  @include prefix-property(
    box-shadow,
    (
      0 0 0 $border-width-focus-inner $border-color-focus-inner,
      0 0 0 $border-width-focus-outer $border-color-focus-outer
    )
  );
}

@mixin translate($x, $y) {
  @include prefix-property(transform, translate($x, $y));
}

@mixin transition($delay, $duration, $property, $timing_function) {
  @include prefix-property(transition-delay, $delay); // default 0s
  @include prefix-property(transition-duration, $duration); // default 0s
  @include prefix-property(transition-property, $property); // default all
  @include prefix-property(transition-timing-function, $timing_function); // default ease
}

@mixin capitalize-first-letter {
  text-transform: lowercase;

  &::first-letter {
    text-transform: uppercase;
  }
}

@mixin box-shadow {
  @include prefix-property(box-shadow, (0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 10px rgba(0, 0, 0, 0.15)));
}

@mixin prefix-pseudo-element($element) {
  &::-webkit-#{$element} {
    @content;
  }

  &::-moz-#{$element} {
    @content;
  }

  &::-ms-#{$element} {
    @content;
  }

  &::-o-#{$element} {
    @content;
  }

  &::#{$element} {
    @content;
  }
}
