@use 'sass:map';

$primary-black: #051336;
$primary-white: #ffffff;

$primary-colors: (
  'black': $primary-black,
  'white': $primary-white,
);

$image_height: 40px;

// neutral/text colors - fading dark base using shades
$neutral-color-base: map-get($primary-colors, 'black') !default; //#051336
$neutral-color-900: #1e2b4a !default;
$neutral-color-800: #37425e !default;
$neutral-color-700: #505a72 !default;
$neutral-color-600: #697186 !default;
$neutral-color-500: #82899b !default;
$neutral-color-400: #9ba1af !default;
$neutral-color-300: #b4b8c3 !default;
$neutral-color-200: #cdd0d7 !default;
$neutral-color-100: #e6e7eb !default;
$neutral-color-50: #f2f3f5 !default;
$neutral-color-0: map-get($primary-colors, 'white') !default; //#FFFFFF

// stylelint-disable-next-line scss/dollar-variable-default
$neutral-colors: (
  'base': $neutral-color-base,
  '900': $neutral-color-900,
  '800': $neutral-color-800,
  '700': $neutral-color-700,
  '600': $neutral-color-600,
  '500': $neutral-color-500,
  '400': $neutral-color-400,
  '300': $neutral-color-300,
  '200': $neutral-color-200,
  '100': $neutral-color-100,
  '50': $neutral-color-50,
  '0': $neutral-color-0,
) !default;

// reds
$red-700: #871313 !default;
$red-600: #b32013 !default;
$red-500: #dc3616 !default;
$red-400: #ff541e !default;
$red-300: #ff6e46 !default;
$red-200: #ff947b !default;
$red-100: #ffc6bb !default;
$red-50: #ffe1de !default;

// stylelint-disable-next-line scss/dollar-variable-default
$reds: (
  '700': $red-700,
  '600': $red-600,
  '500': $red-500,
  '400': $red-400,
  '300': $red-300,
  '200': $red-200,
  '100': $red-100,
  '50': $red-50,
) !default;

// greens
$green-700: #006117 !default;
$green-600: #00882f !default;
$green-500: #00ab50 !default;
$green-400: #00c670 !default;
$green-300: #16d892 !default;
$green-200: #50e8bb !default;
$green-100: #9ff3de !default;
$green-50: #defcf5 !default;

// stylelint-disable-next-line scss/dollar-variable-default
$greens: (
  '700': $green-700,
  '600': $green-600,
  '500': $green-500,
  '400': $green-400,
  '300': $green-300,
  '200': $green-200,
  '100': $green-100,
  '50': $green-50,
) !default;

// blues
$blue-800: #051336 !default;
$blue-700: #182587 !default;
$blue-600: #1237b5 !default;
$blue-500: #0851df !default;
$blue-400: #2371f1 !default;
$blue-300: #4992fd !default;
$blue-200: #7cb4ff !default;
$blue-100: #b9d9ff !default;
$blue-50: #ddecff !default;

// stylelint-disable-next-line scss/dollar-variable-default
$blues: (
  '800': $blue-800,
  '700': $blue-700,
  '600': $blue-600,
  '500': $blue-500,
  '400': $blue-400,
  '300': $blue-300,
  '200': $blue-200,
  '100': $blue-100,
  '50': $blue-50,
) !default;

$spacing_base: 4px;
$spacing_xsmall: $spacing_base * 0.5; // 2px
$spacing_small: $spacing_base; // 4px
$spacing_medium: $spacing_base * 2; // 8px
$spacing_large: $spacing_base * 3; // 12px
$spacing_xlarge: $spacing_base * 4; // 16px
$spacing_2xlarge: $spacing_base * 5; // 20px
$spacing_5xlarge: $spacing_base * 8; // 32px
$spacing_7xlarge: $spacing_base * 10; // 40px
$spacing_8xlarge: $spacing_base * 11; // 44px
$spacing_9xlarge: $spacing_base * 12; // 48px

$font-family-base: 'Muli', 'Helvetica', 'Arial', sans-serif;

$line-height-xsmall: 16px;
$line-height-small: 18px;
$line-height-code: $line-height-small;
$line-height-medium: 20px;
$line-height-header: 25px;
$line-height-large: 28px;
$line-height-xlarge: 30px;
$line-height-2xlarge: 36px;
$line-height-3xlarge: 40px;
$line-height-base: $line-height-medium;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-bold: 700;
$font-weight-xbold: 800;
$font-weight-base: $font-weight-regular;

$font-size-xsmall: 12px !default;
$font-size-code: $font-size-xsmall !default;
$font-size-small: 14px !default;
$font-size-medium: 16px !default;
$font-size-large: 20px !default;
$font-size-xlarge: $font-size-xsmall * 2 !default; // 24px
$font-size-2xlarge: $font-size-small * 2 !default; // 28px
$font-size-3xlarge: $font-size-medium * 2 !default; // 32px
$font-size-base: $font-size-medium !default;

$border-width: 1px !default;
$border-width-bold: 2px !default;
$border-color: $neutral-color-100 !default;
