@import 'src/styles/variables';
@import 'src/styles/fonts';
@import 'src/styles/animations';

$MAIN_CLASS: 'UploadThumb';

.#{$MAIN_CLASS} {
  width: 100px;
  height: 100px;
  position: relative;
  background-color: map-get($neutral-colors, '50');
  border: 2px dashed map-get($neutral-colors, '200');
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: map-get($neutral-colors, '700');
  transition: all 0.2s linear, outline 0;
  svg {
    path {
      transition: fill 0.2s linear;
    }
  }
  &:hover,
  &:focus-within {
    background-color: map-get($blues, '50');
    border-color: map-get($blues, '400');
    color: map-get($blues, '400');
    svg {
      path {
        fill: map-get($blues, '400');
      }
    }
    .actions {
      opacity: 1 !important;
    }
  }
  small {
    padding-top: $spacing_large;
  }
  .thumb-preview {
    display: flex;
    cursor: pointer;
    width: 100px;
    height: 100px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: absolute;
    top: -2px;
    left: -2px;
    border: 1px solid map-get($neutral-colors, '300');
    z-index: 20;
    background-color: #fff;
    .actions {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      left: 0;
      top: 0;
      width: 100px;
      height: 100px;
      z-index: 22;
      opacity: 0;
      transition: opacity 0.2s linear;
      background-color: rgba(255, 255, 255, 0.8);
      .flight-button--link--has-underline:hover:enabled,
      .flight-button--link--has-underline:focus:enabled {
        outline: auto;
        outline-offset: -5px;
      }
    }
    .bt__remove {
      color: map-get($reds, '500') !important;
      padding-top: 10px;
    }
    img,
    svg {
      object-fit: contain;
      width: 100%;
      height: auto;
    }
  }
  .logo-upload-input {
    display: block !important;
    width: 100px;
    height: 100px;
    overflow: hidden;
    position: absolute;
    cursor: pointer;
    left: 0;
    top: 0;
    z-index: 15;
    opacity: 0;
    outline: none;
  }
}
