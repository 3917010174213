@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap');
.sso_font {
  font-family: $font-family-base;
  font-style: normal;
  line-height: $line-height-base;
  font-weight: $font-weight-base;
  font-size: $font-size-base;

  &--xsmall {
    font-size: $font-size-xsmall;
    line-height: $line-height-xsmall;
  }

  &--small {
    font-size: $font-size-small;
    line-height: $line-height-small;
  }

  &--large {
    font-size: $font-size-large;
    line-height: $line-height-header;
  }

  &--xlarge {
    font-size: $font-size-xlarge;
    line-height: $line-height-xlarge;
  }

  &--2xlarge {
    font-size: $font-size-2xlarge;
    line-height: $line-height-2xlarge;
  }

  &--3xlarge {
    font-size: $font-size-3xlarge;
    line-height: $line-height-3xlarge;
  }

  // font weight modifiers
  &--light {
    font-weight: $font-weight-light;
  }

  &--bold {
    font-weight: $font-weight-bold;
  }

  &--xbold {
    font-weight: $font-weight-xbold;
  }
}
.mono {
  font-family: 'Roboto Mono', monospace;
}
