@import 'src/styles/variables';
@import 'src/styles/fonts';
@import 'src/styles/animations';

$MAIN_CLASS: 'ConfigTemplate';
.scope-error {
  color: map-get($reds, '500');
  margin-top: -16px;
  display: flex;
  align-items: center;
  gap: 4px;
}
.#{$MAIN_CLASS} {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: $spacing_7xlarge * 2;
  .section {
    &__tabs {
      &-header {
        display: flex;
        margin-top: $spacing_2xlarge;
        margin-bottom: $spacing_5xlarge;
        box-shadow: 0 0.5px 0 map-get($neutral-colors, '200');
        > a {
          margin-left: auto;
          font-size: 14px;
          line-height: 18px;
          text-decoration: none;
        }
        &-item {
          &.flight-radio-button-wrapper {
            .flight-radio-button {
              height: auto;
              flex-direction: column-reverse;
              font-size: 14px;
              line-height: 18px;
              margin-right: $spacing_7xlarge;
              &__span {
                color: map-get($neutral-colors, '700');
                padding: 0;
              }
              &__radio {
                width: 100%;
                height: 4px;
                border: 2px solid transparent;
                margin-top: $spacing_large;
                border-radius: 0;
                &--selected {
                  border-radius: 0;
                  ~ .flight-radio-button__span {
                    color: map-get($primary-colors, 'black');
                    font-weight: bold;
                  }
                  border-color: map-get($blues, '500');
                }
              }
            }
          }
        }
      }
    }
    &__description,
    &__form {
      font-size: 14px;
      line-height: 148%;
      color: map-get($neutral-colors, '700');
      margin-bottom: $spacing_2xlarge;
      .flight-toggle-switch__span {
        font-weight: 700;
      }
      .warning {
        display: flex;
        background-color: #fdf9d4;
        padding: $spacing_large;
        box-sizing: border-box;
        .icon {
          width: $spacing_7xlarge;
          padding-right: $spacing_small;
          svg {
            width: 20px;
            height: 20px;
          }
        }
        .message {
          font-size: 14px;
          line-height: 18px;
          display: flex;
          flex-direction: column;
          strong {
            color: map-get($neutral-colors, '900');
            opacity: 0.95;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            margin: 0;
          }
          p {
            color: map-get($primary-colors, 'black');
          }
          .links {
            display: flex;
            width: 100%;
            justify-content: flex-end;
          }
          a {
            color: map-get($neutral-colors, '900');
            text-align: right;
            opacity: 0.9;
            font-weight: bold;
            text-decoration: none;
          }
          .approve-link {
            margin-left: $spacing_2xlarge;
            &.flight-button--link {
              color: map-get($neutral-colors, '900');
              text-align: right;
              opacity: 0.9;
              font-weight: bold !important;
              text-decoration: none;
            }
          }
        }
      }
      > div {
        margin-bottom: $spacing_xlarge;
      }
      .group {
        display: flex;
        gap: $spacing_large;
        position: relative;
      }
    }
  }
}
