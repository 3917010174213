@import 'src/styles/variables';

.side-panel {
  display: flex;
  flex-direction: column;
  width: 520px;
  flex: 0 0 520px;
  height: 100%;
  transition: flex 450ms, width 450ms, opacity 100ms;
  opacity: 1;
  background-color: #fff;
  border-left: 1px solid map-get($neutral-colors, '200');
  position: relative;
  z-index: 20;

  &.hidden {
    opacity: 0;
    overflow: hidden;
    width: 0;
    flex: 0 0 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 25px;
  }

  &__title {
    font-size: $font-size-large;
    font-weight: $font-weight-bold;
    color: $primary-black;
  }

  &__close {
    cursor: pointer;
  }

  &__content {
    flex: 1 1 auto;
    padding: 20px $spacing_7xlarge;
    overflow: auto;
    form > div {
      margin-bottom: $spacing_5xlarge * 1.5;
    }
    h2 {
      font-weight: bold;
      font-size: 20px;
      line-height: 35px;
      color: map-get($neutral-colors, '900');
      margin: 0;
    }
    button {
      &:focus:enabled {
        outline: auto;
      }
    }
    .info {
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: map-get($neutral-colors, '700');
      &.small {
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        padding-top: $spacing_large;
      }
    }
    h3 {
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: map-get($neutral-colors, '900');
    }
    .roles {
      width: 100%;
      height: 100%;
      display: flex;
      border: 1px solid map-get($neutral-colors, '100');
      box-sizing: border-box;
      margin: $spacing_large 0;
      padding: $spacing_2xlarge;
      flex-wrap: wrap;
      gap: $spacing_xlarge 0;
      position: relative;
      .flight-checkbox-wrapper {
        flex: 50%;
        .flight-checkbox-label {
          font-size: 12px;
          line-height: 15px;
          color: map-get($neutral-colors, '700');
        }
      }
    }
    .button_delete {
      color: map-get($neutral-colors, '600');
      background-color: #fff;
      box-shadow: none;
      > svg {
        fill: map-get($neutral-colors, '600');
      }
      &:hover:enabled,
      &:focus:enabled {
        color: map-get($reds, '500');
        background-color: map-get($neutral-colors, '50');
        > svg {
          fill: map-get($reds, '500');
        }
      }
    }
  }

  &__footer {
    flex-shrink: 0;
    border-top: 1px solid $neutral-color-100;
    padding: 20px;
  }
}
