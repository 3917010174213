@import 'src/styles/variables';
@import 'src/styles/fonts';
@import 'src/styles/animations';

$MAIN_CLASS: 'PreDefinedAttributes';

.#{$MAIN_CLASS} {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: $spacing_7xlarge * 2;
  .name-col {
    font-weight: 700;
    color: map-get($blues, '500');
  }

  &__wrapper {
    margin-bottom: 40px;
    max-width: 1106px;
    width: 100%;
    display: flex;
    flex-direction: column;
    .full-width & {
      max-width: 100%;
      > div {
        max-width: 1106px;
        margin-left: 10%;
        margin-right: 10%;
      }
    }
    &.with-separator {
      box-shadow: 0 1px 0 map-get($neutral-colors, '200');
      background-color: #fff;
    }
    h4 {
      margin: 3rem 0 0;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: $spacing_5xlarge;
    align-items: center;
  }
  &__panel {
    position: fixed;
    right: 0;
    top: 60px;
    box-shadow: -20px 0px 50px 0px rgba(0, 0, 0, 0.2);
    .side-panel__content {
      flex: 1 1 auto;
      padding: 0 40px;
    }
  }
  &__filters {
    display: flex;
    gap: 0 20px;
  }
  &__table {
    margin: $spacing_5xlarge 0;
    &-content {
      table {
        border: 1px solid map-get($neutral-colors, '200');
      }
      thead {
        tr {
          background-color: map-get($neutral-colors, '50');
          font-size: 12px;
          th {
            &:first-child {
              width: 50px;
            }
            &:nth-child(2) {
              width: 50%;
            }
            &:last-child {
              width: 40px;
              text-align: center;
            }
          }
        }
      }
      tbody {
        td {
          &:last-child,
          &:first-child {
            text-align: center;
          }
          &:first-child {
            padding-right: 20px;
          }
        }
      }
    }
  }
}
