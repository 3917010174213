@import 'src/styles/variables';
@import 'src/styles/fonts';
@import 'src/styles/mixins';
@import 'src/styles/animations';

$MAIN_CLASS: 'static';
$CONTENT_CLASS: $MAIN_CLASS + '__content';
$ITEM_CLASS: $CONTENT_CLASS + '__item';

.#{$MAIN_CLASS} {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__icon {
    padding: $spacing_7xlarge 0 0;
  }
}

.#{$CONTENT_CLASS} {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__wrapper {
    margin-bottom: 40px;
    max-width: 760px;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }
  &__button_link.flight-button--link {
    font-weight: normal;
    color: map-get($blues, '500');
    align-self: flex-start;
    margin-bottom: 16px;
    span {
      padding: 0;
    }
    &.small {
      font-size: 12px;
      line-height: 15px;
      span {
        padding: 8px 12px;
      }
    }
    &:hover:enabled,
    &:focus:enabled {
      color: map-get($blues, '500');
      span {
        color: map-get($blues, '500');
      }
    }
    &-back {
      align-self: flex-start;
      margin-bottom: $spacing_xlarge;
    }
  }
  &__infos {
    &-wrapper {
      width: 100%;
      font-style: normal;
      font-size: 14px;
      line-height: 18px;
      color: map-get($neutral-colors, '700');
      display: flex;
      flex-direction: column;
      border-top: 1px solid map-get($neutral-colors, '200');
      margin-top: $spacing_7xlarge;
    }
  }
  &__heading-text {
    @extend .sso_font;
    @extend .sso_font--xlarge;
    @extend .sso_font--bold;
    margin-top: $spacing_7xlarge;
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
  }
  &__button.flight-button--link {
    font-weight: normal;
    span {
      padding: 0;
    }
  }
  &__button_deactivate.flight-button--link {
    font-weight: normal;
    span {
      padding: 0;
    }
    color: map-get($reds, '400');
    &:hover:enabled,
    &:focus:enabled {
      color: map-get($reds, '400');
      span {
        color: map-get($reds, '400');
      }
    }
  }
  &__modal {
    max-width: 480px !important;
    overflow: hidden;
  }
  &__input {
    margin-top: $spacing_2xlarge;
    &__inline {
      margin-right: $spacing_large;
    }
  }
  &__row {
    margin-top: $spacing_5xlarge;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-end;
  }
  &__link {
    margin-top: 80px;
    padding: $spacing_medium 0;
    @extend .sso_font--small;
    text-decoration: none;
    &:active,
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  form {
    position: relative;
  }
  &__info {
    width: fit-content;
  }
}
