.flight-text-input {
  &__input {
    border-radius: 5px 5px 0px 0px;
  }
}

.flight-select__dropdown {
  border-radius: 5px 5px 0px 0px;
}

.form__error {
  @extend .sso_font;
  @extend .sso_font--xsmall;
  opacity: 1;
  color: map-get($reds, '400');
  margin-top: $spacing_xlarge;
  margin-bottom: $spacing_xlarge;
  transition: height 0.5s ease;
  animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation-delay: 0.4s;
}
