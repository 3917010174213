@import 'src/styles/variables';
@import 'src/styles/fonts';
@import 'src/styles/animations';

$MAIN_CLASS: 'ShareDatasourceModal';

.#{$MAIN_CLASS} {
  max-width: 352px !important;
  &__alert {
    .flight-button--primary {
      margin-left: $spacing_xlarge;
    }
    &.flight-modal--warning {
      .flight-button--primary {
        color: #fff;
        background-color: map-get($reds, '400');
        &:hover:enabled,
        &:focus:enabled {
          background-color: map-get($reds, '500');
        }
      }
      .flight-button--link,
      .flight-button--secondary {
        color: map-get($reds, '400');
        font-weight: bold !important;
      }
      .flight-button--secondary {
        box-shadow: inset 0 0 0 1px map-get($reds, '500');
      }
    }
    .flight-modal {
      &__header {
        font-weight: 800 !important;
        font-size: 20px;
        line-height: 25px;
        &__close {
          width: 40px;
          > svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
    &_footer {
      text-align: right;
      padding-bottom: $spacing_large;
    }
    &_content {
      color: map-get($neutral-colors, '700');
      font-size: 14px;
      line-height: 18px;
      &_form {
        display: flex;
        margin-top: $spacing_xlarge;
      }
    }
    .flight-modal__content {
      font-size: 14px;
      line-height: 18px;
      p {
        margin: 0 0 $spacing_2xlarge;
      }
      ul {
        margin: 0 0 0 $spacing_2xlarge;
        padding: 0;
        li {
          margin-bottom: $spacing_2xlarge;
        }
      }
      .checkboxes {
        margin-bottom: $spacing_7xlarge;
        .checkbox {
          display: flex;
          margin-bottom: $spacing_2xlarge;
          .flight-checkbox-wrapper {
            align-items: flex-start;
            .flight-checkbox-label {
              color: map-get($primary-colors, 'black');
              font-size: 14px;
              line-height: 18px;
            }
          }
        }
      }
    }
    .flight-modal__content + .flight-modal__footer {
      padding: $spacing_medium;
    }
  }
  .modal_buttons {
    text-align: right;
    button {
      margin-left: 8px;
    }
  }

  .flight-modal-wrapper .flight-modal .flight-modal__header {
    padding: 20px 50px 12px 24px !important;
    position: relative !important;
  }
  .flight-modal-wrapper .flight-modal--small {
    max-width: 452px;
  }
}
