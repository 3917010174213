@import 'src/styles/variables';
@import 'src/styles/fonts';

.custom__navigation,
.flight-nav__header__user__dropdown__portal {
  margin-right: 16px;
  .flight-button__label {
    padding: 0 !important;
  }
  .flight-nav__header__user {
    margin-right: 0;
    &__avatar {
      display: inline-flex;
      border-radius: 5px;
      &.flight-avatar--w,
      &.flight-avatar--x {
        background-color: #2d825d;
      }
    }
    > .flight-dropdown {
      padding: 6px;
      background-color: #fff;
      border-radius: 5px;
      width: 32px;
      transition: width 0.3s ease-out, background-color 0.3s ease-out 0.3s;
      &__dropdown {
        &:focus {
          outline: 2px solid map-get($blues, '800');
        }
      }
      .flight-dropdown__trigger {
        &:focus,
        &:focus-within {
          &:hover {
            outline: 2px solid map-get($blues, '500');
          }
          outline: 2px solid map-get($blues, '800');
        }
        .trigger-org-name {
          transition: all 0.4s ease-out 0.6s;
          font-weight: bold;
          font-size: 14px;
          line-height: 18px;
          color: transparent;
          padding-inline-start: 12px;
          font-size: 0;
          display: inline-block;
          margin: 0;
        }
      }
    }
    &.has-organization {
      > .flight-dropdown {
        background-color: map-get($neutral-colors, '50');
        width: 100%;
        .flight-dropdown__trigger {
          .trigger-org-name {
            color: #000;
            font-size: 14px;
          }
        }
      }
      .flight-nav__header__user__profile__group {
        border-bottom: 1px solid map-get($neutral-colors, '200');
      }
    }
    &__profile {
      &__group {
        border-bottom: 1px solid map-get($neutral-colors, '200');
        h5 {
          font-style: normal;
          font-weight: 800;
          font-size: 12px;
          line-height: 15px;
          display: flex;
          align-items: center;
          color: map-get($primary-colors, 'black');
          margin: 3px 0;
          padding: 8px 16px;
        }
      }
      &__links,
      &__signout {
        box-sizing: border-box;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: map-get($neutral-colors, 'base');
        border: none;
        box-shadow: none;
        display: flex;
        text-decoration: none;
        // height: 42px;
        align-items: center;
        transition: background-color 0.2s;
        width: 100%;
        text-align: left;
        justify-content: flex-start;
        padding: 8px 16px;
        label {
          padding: 0 !important;
        }
        &:hover,
        &:focus {
          background-color: map-get($neutral-colors, '50');
        }
        &:focus {
          outline: 2px solid map-get($blues, '800');
        }
      }
      &__signout {
        padding-top: 12px;
      }
      &__name {
        font-size: 16px;
        margin-top: 20px;
      }
      &__email {
        font-size: 12px;
        line-height: 15px;
        color: map-get($neutral-colors, '700');
      }

      &__org {
        text-align: left;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        margin-top: 16px;
        padding: 0 $spacing_2xlarge $spacing_2xlarge;
        border-bottom: 1px solid map-get($neutral-colors, '200');
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          margin-right: 8px;
        }
      }
      &__avatar {
        width: 48px;
        height: 48px;
        border-radius: 10px;
      }
    }
  }
}
