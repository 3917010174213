@import '../../styles/variables';
@import '../../styles/fonts';
@import '../../styles/mixins';
@import '../../styles/animations';

$MAIN_CLASS: 'org_users_list';
$CONTENT_CLASS: $MAIN_CLASS + '__content';
$FORM_CLASS: $MAIN_CLASS + '__form';

.#{$CONTENT_CLASS} {
  &__infos {
    &-wrapper {
      margin-top: $spacing_7xlarge;
      &_list {
        width: 100%;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: map-get($neutral-colors, '600');
        display: flex;
        flex-direction: column;
        border: 1px solid map-get($neutral-colors, '200');
        border-bottom: 0;
        margin-top: $spacing_2xlarge;
        p {
          margin-top: 0;
          margin-bottom: $spacing_small;
          strong {
            color: map-get($blues, '500');
          }
        }
      }
    }
  }
}
