@import 'src/styles/variables';
@import 'src/styles/fonts';
@import 'src/styles/animations';

$MAIN_CLASS: 'TeamMembersModal';
$TABLE_CLASS: 'TeamDetails__content__tabs-content-table';

.#{$MAIN_CLASS} {
  .flight-modal {
    &__header {
      box-shadow: 0 1px 0 map-get($neutral-colors, '100');
      margin-bottom: $spacing_large;
    }
    &__content {
      min-height: 50vh;
      padding: 0 !important;
      overflow: hidden;
      .#{$TABLE_CLASS} .flight-table-row__cell {
        position: relative;
      }
    }
    &__footer {
      text-align: right;
      border-top: 1px solid map-get($neutral-colors, '100');
      margin-top: $spacing_2xlarge;
      button {
        margin-left: $spacing_2xlarge;
      }
    }
  }
  &__search_field {
    padding: $spacing_xlarge $spacing_5xlarge;
  }
  &__members_list {
    // border-top: 1px solid map-get($neutral-colors, '200');
    padding: 0 32px;
    .info-text {
      padding: 16px 0 32px;
    }
    .flight-table-wrapper .flight-table thead th:first-child,
    .flight-table-wrapper .flight-table tbody td:first-child {
      padding: 0 10px;
    }
    .flight-table-row__cell {
      &:first-child {
        width: 56px;
      }
      > div {
        padding: 0;
      }
    }
  }
  .checkbox__team {
    .checkbox {
      display: grid;
      grid-template-columns: min-content auto;
      grid-gap: 0;
      font-size: 16px;
      color: map-get($primary-colors, 'black');
      .checkbox--disabled {
        color: map-get($neutral-colors, '400');
      }
      &__input {
        display: grid;
        grid-template-areas: 'checkbox';

        > * {
          grid-area: checkbox;
        }
        input {
          opacity: 0;
          width: 16px;
          height: 16px;
        }
        input:checked + .checkbox__control svg {
          animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) forwards;
          fill: #fff;
        }
        input:checked + .checkbox__control {
          background-color: map-get($blues, '500');
          border-color: transparent;
        }
        input:focus + .checkbox__control {
          box-shadow: 0 0 0 0.05em #fff, 0 0 0.15em 0.1em map-get($blues, '500');
        }
        input:checkbox:disabled + .checkbox__control {
          color: map-get($neutral-colors, '400');
        }
      }
      &__control {
        display: inline-grid;
        width: 16px;
        height: 16px;
        border-radius: 4px;
        align-items: center;
        justify-content: center;
        border: 1px solid map-get($neutral-colors, '600');
        svg {
          fill: none;
        }
      }
    }
  }
}
@keyframes stroke {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
