@import 'src/styles/variables';
@import 'src/styles/mixins';
@import 'src/styles/fonts';
@import 'src/styles/animations';

$MAIN_CLASS: 'project_users_list';
$CONTENT_CLASS: $MAIN_CLASS + '__content';

.#{$CONTENT_CLASS} {
  &__button {
    &_team {
      &-toggle {
        color: map-get($primary-colors, 'black');
        span {
          font-weight: bold;
          display: flex;
          align-items: center;
          svg {
            margin-left: 14px;
          }
        }
      }
    }
  }
  &__infos {
    &-item {
      display: flex;
      border-bottom: 1px solid map-get($neutral-colors, '200');
      padding: 12px;
      align-items: center;
      flex-wrap: wrap;
      small {
        font-size: 12px;
        line-height: 15px;
        color: map-get($neutral-colors, '700');
      }
      &-left {
        width: 50%;
        p {
          margin-top: 0;
          margin-bottom: $spacing_small;
          strong {
            color: map-get($primary-colors, 'black');
          }
        }
        &.team {
          p strong {
            color: map-get($blues, '500');
          }
          &.subteam {
            p {
              padding-inline-start: 24px;
            }
          }
        }
      }
      &-right {
        color: map-get($primary-colors, 'black');
        text-transform: capitalize;
        width: 50%;
        // font-size: 12px;
        // line-height: 15px;

        &.team {
          display: flex;
          align-items: center;
        }

        &-small {
          color: map-get($neutral-colors, '700');
          text-transform: none;
          margin-top: 1rem;
          font-size: 12px;
          line-height: 15px;
          a {
            color: map-get($blues, '500');
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      &-subteams-list {
        text-align: right;
      }
      &-subteam {
        // flex: 0 0 100%;
        width: 100%;
        margin: -12px;
        background-color: #fafafa;
        border-bottom: 0;
        height: 0;
        overflow: hidden;
        opacity: 0;
        border-top: 1px solid map-get($neutral-colors, '200');
        transition: all 0.2s ease;
        &.open {
          height: 38px;
          opacity: 1;
          margin-top: 12px;
        }
      }
    }
  }
}
