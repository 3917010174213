@import 'src/styles/variables';
@import 'src/styles/fonts';
@import 'src/styles/animations';

$MAIN_CLASS: 'Connector';
$CONTENT_CLASS: $MAIN_CLASS + '__content';
$FORM_CLASS: $MAIN_CLASS + '__form';
$ITEM_CLASS: $FORM_CLASS + '__item';
$LOADING_CLASS: $MAIN_CLASS + '__loading';

.full-width {
  max-width: 100%;
  margin: 0;
  padding: 0;
}
.container {
  max-width: 1106px;
  width: 100%;
}

.#{$MAIN_CLASS} {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .info-text {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: $spacing_5xlarge;
  }

  &__alert {
    .flight-button--primary {
      margin-left: $spacing_xlarge;
      &.delete-full {
        color: #fff;
        background-color: map-get($reds, '400');
        &:hover:enabled,
        &:focus:enabled {
          background-color: map-get($reds, '500');
        }
      }
    }
    &.flight-modal--warning {
      .flight-button--primary {
        color: #fff;
        background-color: map-get($reds, '400');
        &:hover:enabled,
        &:focus:enabled {
          background-color: map-get($reds, '500');
        }
      }
      .flight-button--link,
      .flight-button--secondary {
        color: map-get($reds, '400');
        font-weight: bold !important;
      }
      .flight-button--secondary {
        box-shadow: inset 0 0 0 1px map-get($reds, '500');
      }
    }
    .flight-modal {
      &__header {
        font-weight: 800 !important;
        font-size: 20px;
        line-height: 25px;
        &__close {
          width: 40px;
          > svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
    &_footer {
      text-align: right;
    }
  }

  &__icon {
    padding: $spacing_7xlarge 0 0;
  }
}

.#{$CONTENT_CLASS} {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__wrapper {
    margin-bottom: $spacing_2xlarge;
    max-width: 1106px;
    width: 100%;
    display: flex;
    flex-direction: column;
    .full-width & {
      max-width: 100%;
      > div {
        margin: 0 $spacing_5xlarge;
      }
    }
    &.with-separator {
      box-shadow: 0 1px 0 map-get($neutral-colors, '200');
      background-color: #fff;
    }
    &.with-tabs {
      flex-direction: row;
    }
    h4 {
      margin: 3rem 0 0;
    }
  }
  &__tabs {
    display: flex;
    margin-right: $spacing_7xlarge * 1.5;
    padding-inline-start: $spacing_large;
    flex-direction: column;
    width: 260px;
    box-sizing: border-box;
    > div {
      display: flex;
      svg {
        margin-right: $spacing_large;
        position: relative;
        z-index: 10;
        padding-top: $spacing_medium;
      }
      > div {
        width: 100%;
      }
    }
    &-header {
      &-item {
        &.flight-radio-button-wrapper {
          .flight-radio-button {
            height: 40px;
            flex-direction: column;
            font-size: 14px;
            line-height: 18px;
            margin-bottom: $spacing_large;
            &:focus-within {
              .flight-radio-button__span {
                outline: 2px solid $blue-600;
              }
            }
            &__span {
              border-radius: 5px;
              color: map-get($primary-colors, 'black');
              padding: $spacing_large;
              width: 100%;
              text-align: left;
            }
            &__radio {
              width: 100%;
              height: 1px;
              border-radius: 5px;
              border: 0;
              &--selected {
                ~ .flight-radio-button__span {
                  background-color: map-get($blues, '50');
                  color: map-get($primary-colors, 'black');
                  font-weight: bold;
                }
              }
            }
          }
        }
      }
    }
    &-content {
      width: 60vw;
      padding-bottom: $spacing_5xlarge;
      margin-bottom: $spacing_5xlarge;
    }
  }
  &__header {
    display: flex;
    gap: 0 20px;
    justify-content: space-between;
    align-items: center;
    height: 2 * $spacing_7xlarge;

    > h1,
    div {
      display: flex;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 30px;
      align-items: center;
      color: map-get($primary-colors, 'black');
      > span {
        padding-inline-start: $spacing_medium;
        font-weight: normal;
      }
      .updated-at {
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        color: map-get($neutral-colors, '700');
        padding-right: $spacing_2xlarge;
      }
    }
    .row-icon {
      display: flex;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      margin-right: $spacing_2xlarge;
      img,
      svg {
        object-fit: contain;
        width: 100%;
        height: auto;
      }
    }
  }

  &__button {
    font-weight: normal;
    span {
      padding: 0;
    }
  }
  &__button_delete {
    color: #fff;
    background-color: map-get($reds, '400');
    &:hover:enabled,
    &:focus:enabled {
      background-color: map-get($reds, '500');
    }
  }
  &__button_link {
    font-weight: normal;
    span {
      padding: 0;
    }
    &.small {
      font-size: 12px;
      line-height: 15px;
      span {
        padding: 08px 12px;
      }
    }
    color: map-get($blues, '500');
    &:hover:enabled,
    &:focus:enabled {
      color: map-get($blues, '500');
      span {
        color: map-get($blues, '500');
      }
    }
    &-back {
      align-self: flex-start;
      margin-bottom: $spacing_xlarge;
    }
  }
  &__button_delete_link {
    font-weight: normal;
    span {
      padding: 0;
    }
    color: map-get($reds, '400');
    &:hover:enabled,
    &:focus:enabled {
      color: map-get($reds, '400');
      span {
        color: map-get($reds, '400');
      }
    }
  }
  &__button_manage {
    color: map-get($blues, '500');
    float: right;
    font-weight: normal;
    span {
      padding: 0;
    }
    &:hover:enabled,
    &:focus:enabled {
      color: map-get($blues, '500');
      span {
        color: map-get($blues, '500');
      }
    }
  }
  &__modal {
    max-width: 480px !important;
  }
  &__input {
    margin-top: $spacing_2xlarge;
    &__inline {
      margin-right: $spacing_large;
    }
  }
  &__row {
    margin-top: $spacing_5xlarge;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-end;
  }
  &__link {
    margin-top: 80px;
    padding: $spacing_medium 0;
    @extend .sso_font--small;
    text-decoration: none;
    &:active,
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  form {
    position: relative;
  }
}

.section {
  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
    color: map-get($primary-colors, 'black');
    margin-bottom: $spacing_2xlarge;
  }
  &__subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 1;
    color: map-get($neutral-colors, '700');
    margin: $spacing_large 0 0;
  }
  &__fields {
    &-field {
      margin-bottom: $spacing_5xlarge;
      // Loading blocks
      .content-placeholder-text {
        width: 100%;
      }
      .flight-text-area {
        padding: 0 0 24px;
      }
      label {
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        color: map-get($primary-colors, 'black');
        display: flex;
        align-items: center;
        column-gap: 5px;
        margin: 1em 0;
      }
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 15px;
        color: map-get($neutral-colors, '700');
      }
    }
  }
  &__delete {
    border-top: 1px solid map-get($neutral-colors, '200');
    padding-top: $spacing_2xlarge;
    h2 {
      font-size: 16px;
      line-height: 20px;
    }
    p {
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: map-get($neutral-colors, '700');
    }
    .flight-button--primary {
      color: #fff;
      background-color: map-get($reds, '400');
      &:hover:enabled,
      &:focus:enabled {
        background-color: map-get($reds, '500');
      }
    }
  }
}
.modal_buttons {
  text-align: right;
  button {
    margin-left: 8px;
  }
}

.#{$LOADING_CLASS} {
  width: 100%;
  text-align: center;

  svg:last-child {
    fill: map-get($reds, '500');
  }
}

.flight-modal-wrapper .flight-modal .flight-modal__header {
  padding: 20px 50px 12px 24px !important;
  position: relative !important;
}
.flight-modal-wrapper .flight-modal .flight-modal__content {
  .input_remove {
    padding: $spacing_2xlarge 0 $spacing_7xlarge;
  }
}
.delete-connector {
  .flight-button--primary:enabled {
    color: #fff;
    background-color: map-get($reds, '400');
    &:hover,
    &:focus {
      background-color: map-get($reds, '500');
    }
  }
}

img:not([src]):not([srcset]) {
  visibility: hidden;
}
