@import 'src/styles/variables';
@import 'src/styles/fonts';
@import 'src/styles/animations';

$MAIN_CLASS: 'DeploymentModal';

.#{$MAIN_CLASS} {
  &__error-message {
    font-size: 14px;
    color: map-get($primary-colors, 'black');
    margin: 0;
    padding: $spacing_large;
    background-color: map-get($reds, '50');
    display: flex;
    align-items: center;
    > svg {
      margin-right: $spacing_medium;
    }
  }
  .flight-modal {
    &__header {
      box-shadow: 0 1px 0 map-get($neutral-colors, '100');
      margin-bottom: $spacing_large;
      > span {
        font-weight: 800;
        font-size: 20px;
        line-height: 25px;
      }
    }
    &__content {
      min-height: 30vh;
      overflow: hidden;
    }
    &__footer {
      text-align: right;
      border-top: 1px solid map-get($neutral-colors, '100');
      margin-top: $spacing_2xlarge;
      button {
        margin-left: $spacing_large;
        &.flight-button--primary {
          background-color: map-get($blues, '500');
          &:hover:enabled,
          &:focus:enabled {
            background-color: map-get($blues, '600');
          }
          &:disabled {
            background-color: map-get($neutral-colors, '100');
            color: map-get($neutral-colors, '400');
          }
        }
      }
    }
  }
  .section {
    &__fields {
      max-width: 100%;
      &-field {
        margin-bottom: $spacing_medium;
        margin-top: $spacing_medium;
      }
      &-field-tags {
        display: flex;
        padding: $spacing_7xlarge 0 0;
        gap: $spacing_medium;
        width: 340px;
        flex-wrap: wrap;
        margin-top: $spacing_small * 1.2;
        > span {
          cursor: pointer;
          &:before {
            content: '\2715';
            border-radius: 0;
            white-space: nowrap;
            font-weight: bold;
            font-size: 10px;
            line-height: 22px;
            padding: 4px 8px 0;
            background-color: map-get($neutral-colors, '50');
            opacity: 0.75;
            box-sizing: border-box;
            vertical-align: bottom;
            color: map-get($neutral-colors, '300');
            border-right: 1px solid map-get($neutral-colors, '300');
            display: inline-flex;
          }
        }
      }
    }
  }
}
