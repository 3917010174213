@import '../variables';
@import '../fonts';

$MAIN_CLASS: 'PasswordReset';
$FORM_CLASS: $MAIN_CLASS + '__form';
$ITEM_CLASS: $FORM_CLASS + '__item';

$image_height: 40px;

.#{$MAIN_CLASS} {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  &__heading-text {
    @extend .sso_font;
    @extend .sso_font--xlarge;
    @extend .sso_font--bold;
    margin-top: $spacing_7xlarge;
    margin-bottom: $spacing_xlarge;
  }

  &__email-sent {
    @extend .sso_font;
    @extend .sso_font--small;
    display: flex;
    align-items: center;
    justify-content: center;
    color: map-get($greens, '600');
    opacity: 1 !important;
    margin-bottom: $spacing_2xlarge;
    svg {
      padding-right: $spacing_medium;
    }
  }

  &__logo {
    width: auto;
    height: $image_height;
    position: relative;
    right: 18px;
  }

  &__form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
  }

  &__reset-snackbar {
    width: 75%;
  }
}

.#{$ITEM_CLASS} {
  width: 50%;
  display: flex;
  align-items: center;

  &__input {
    width: 100%;
  }

  &__button {
    width: 50%;
    margin-top: $spacing_7xlarge;
    margin-bottom: $spacing_5xlarge;
  }

  &__signin {
    a {
      color: #0851df;
    }
  }
  &__link {
    @extend .sso_font--small;
    text-decoration: none;
    a {
      color: #0851df;
    }
    &:active,
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &__password-change-confirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
    svg {
      position: relative;
      top: 5px;
      fill: green;
      width: 28px;
      height: auto;
    }
  }
  &__password-change-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

.#{$FORM_CLASS} {
  width: 50vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}
