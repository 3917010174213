@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/fonts';

.projectListItem {
  &__wrapper {
    margin-bottom: $spacing_5xlarge;
    position: relative;
    &:hover,
    &:focus-within {
      z-index: 30;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    }

    .flight-overflow-menu {
      position: absolute;
      z-index: 10;
      top: 5px;
      right: 5px;
      .flight-button.flight-overflow-menu__trigger {
        width: 45px;
        height: 45px;
        > svg {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  &__extras {
    @include outline-none;
    cursor: pointer;
    background-color: map-get($primary-colors, 'white');
    border-radius: 10px;
    border: $border-width solid map-get($neutral-colors, '200');
    box-sizing: border-box;
    width: 342px;
    background-color: #fff;
    border-radius: 0 0 10px 10px;
    border-top-color: transparent;
    position: absolute;
    padding: 5px 12px 12px;
    z-index: 4;

    // Animation
    transform: translateY(calc(8px - 100%));
    // transition: transform 0.2s ease-in 0.15s;
    // will-change: transform;
    button {
      opacity: 0;
      // transition: all 0.15s linear;
      transform: translateY(-150%);
      // will-change: transform, opacity;
    }

    :hover > &,
    :focus-within > &,
    :focus > & {
      transform: translateY(0);
      // transition: transform 0.3s ease-out;
      // will-change: transform;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
      button {
        opacity: 1;
        transform: translateY(0);
        &:focus {
          outline: 2px solid black;
        }
      }
    }

    &-content {
      display: flex;
      flex-wrap: wrap;
      border-top: 1px solid map-get($neutral-colors, '200');
      padding-top: 12px;
      .flight-button--link {
        svg {
          width: 16px;
          height: 16px;
        }
      }
      .flight-button__label {
        font-weight: normal;
      }

      &__break {
        flex-basis: 100%;
        height: 0;
      }
    }
  }

  &__border.flight-card {
    @include outline-none;
    cursor: pointer;
    background-color: map-get($primary-colors, 'white');
    border-radius: 10px;
    border: $border-width solid map-get($neutral-colors, '200');
    box-sizing: border-box;
    width: 342px;
    height: 232px;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 7;

    border-radius: 10px 10px 0 0;
    border-bottom-color: transparent;

    .flight-card {
      @extend .sso_font;

      &__header {
        display: flex;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 12px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
      &__body {
        display: flex;
        align-items: center;
        flex-direction: column;
        .project {
          &__region {
            font-size: 14px;
            line-height: 18px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #6e7484;
            text-transform: capitalize;
          }
        }
      }

      &__footer {
        @extend .sso_font--small;
        color: map-get($neutral-colors, '700');
        align-items: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin-top: 12px;
      }
    }
  }

  &__inactive {
    .flight-card {
      &__footer {
        color: map-get($reds, '500');
      }
    }
  }

  &__project-icon {
    margin: auto;
  }
}

.flight-tag {
  border-radius: 0 !important;
  white-space: nowrap !important;
  font-weight: bold !important;
  font-size: 12px !important;
  line-height: 18px !important;
  padding: 4px 8px !important;
  display: inline-flex;
  &--success {
    background-color: #f4ffff !important;
    color: #00812c !important;
  }
}
