@import 'src/styles/variables';
@import 'src/styles/fonts';
@import 'src/styles/animations';

$MAIN_CLASS: 'Deployment';

.#{$MAIN_CLASS} {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: $spacing_7xlarge * 2;
  .flight-modal-wrapper .flight-modal--large {
    max-width: 810px;
  }

  .empty-deployments {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    width: 100%;
    padding: $spacing_7xlarge * 1.95;
    row-gap: $spacing_5xlarge;
    border: 1px solid map-get($neutral-colors, '200');
    box-sizing: border-box;
    margin-top: $spacing_2xlarge;
    p {
      font-size: 14px;
      line-height: 18px;
      color: map-get($neutral-colors, '700');
      max-width: 370px;
    }
  }
  &-title {
    font-style: normal;
    font-weight: bold;
    display: flex;
    align-items: center;
    text-align: left;
    margin-left: $spacing_large;
    font-size: 14px;
    line-height: 18px;
    svg {
      margin-right: $spacing_large;
      width: 24px;
      height: 24px;
      flex: 24px;
    }
  }
  &-form {
    display: flex;
    flex-direction: column;
    padding: $spacing_medium $spacing_2xlarge;
    background-color: map-get($neutral-colors, '50');
    flex-wrap: wrap;
    box-sizing: border-box;
    &-wrapper {
      padding: $spacing_2xlarge;
    }
    &-row {
      display: flex;
      width: 100%;
      font-weight: normal;
      font-size: 12px;
      line-height: 30px;
      color: map-get($neutral-colors, '700');
      text-align: left;
      .title {
        font-weight: bold;
        width: 160px;
      }
    }
  }
  .CollapsibleMultiple {
    &-wrapper {
      margin-top: $spacing_2xlarge;
      margin-bottom: $spacing_5xlarge;
    }
    margin-bottom: $spacing_large;
    &__label {
      box-shadow: none;
      border: 1px solid map-get($neutral-colors, '200');
      padding: $spacing_small $spacing_large;

      &:hover,
      &:focus {
        background-color: transparent;
        cursor: pointer;
      }
      &__icon {
        fill: map-get($neutral-colors, '700');
      }
    }
    &__content {
      background-color: #fff;
      border: 1px solid map-get($neutral-colors, '200');
      border-top: 0;
      border-bottom: 0;
    }
    &__header {
      .flight-overflow-menu {
        > button {
          width: 50px;
          &:focus,
          &:hover {
            background-color: transparent;
          }
          svg {
            width: 24px;
            height: 24px;
            flex: 24px;
          }
        }
      }
      > div {
        &:last-child {
          width: auto;
          text-align: left;
          margin-left: auto;
          color: map-get($primary-colors, 'black');
        }
      }
    }
  }
}

.section {
  &__fields {
    &-field {
      margin-bottom: $spacing_medium;
    }
    &-box {
      border: 1px solid map-get($neutral-colors, '200');
      padding: $spacing_small $spacing_2xlarge $spacing_medium;
      margin-top: $spacing_2xlarge;
    }
    &-columns {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
}
