@import '../List/Organizations.scss';

$MAIN_CLASS: 'organizations';
$FORM_CLASS: $MAIN_CLASS + '__form';
$ITEM_CLASS: $FORM_CLASS + '__item';
$LOADING_CLASS: $MAIN_CLASS + '__loading';

.#{$FORM_CLASS} {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  flex-direction: column;
}

.#{$ITEM_CLASS} {
  &__group {
    display: flex;
    flex-wrap: wrap;
  }
  &__remove {
    color: map-get($reds, '500') !important;
    padding-top: 10px;
  }
  &__image-field {
    display: flex;
    width: 80px;
    height: 80px;
    /* N200 */
    border: 1px solid map-get($neutral-colors, '200');
    box-sizing: border-box;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    flex: 0 0 80px;
    margin-right: 20px;
    transition: all 0.2s linear;

    .actions {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      left: 0;
      top: 0;
      width: 80px;
      height: 80px;
      z-index: 11;
      opacity: 0;
      transition: opacity 0.2s linear;
      background-color: rgba(255, 255, 255, 0.8);
      .flight-button--link--has-underline:hover:enabled,
      .flight-button--link--has-underline:focus:enabled {
        outline: auto;
        outline-offset: -5px;
        height: 70px;
      }
    }
    &:hover,
    &:focus,
    &:focus-within,
    &:active {
      border-color: map-get($blues, '500');
      .actions {
        opacity: 1;
      }
    }
    &:focus-within {
      outline: auto;
    }
    .flight-text-input-wrapper {
      position: relative;
      .organizations__form__item__file {
        input {
          position: absolute;
          left: 0;
          top: 0;
          width: 0;
          height: 0;
          margin: 0;
          padding: 0;
        }
      }
      &:focus-within {
        outline: auto;
      }
    }
  }
  width: 100%;
  min-width: 300px;
  display: flex;
  &__button {
    @extend .#{$ITEM_CLASS};
    margin-top: $spacing_5xlarge;
    margin-bottom: $spacing_2xlarge;
  }
  &__input {
    margin-top: $spacing_2xlarge;
    &__inline {
      margin-right: $spacing_large;
    }
  }
  &__row {
    @extend .#{$ITEM_CLASS};
    margin-top: $spacing_5xlarge;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-end;
  }
}

// LoadingSpinner component

.#{$LOADING_CLASS} {
  margin-top: $spacing_7xlarge;
  svg {
    visibility: hidden;
  }

  svg:last-child {
    position: relative;
    visibility: visible;
    fill: map-get($blues, '500');
    // check _mixins.scss for more details
    @include animation(spin, 0s, 0.75s, linear, infinite, normal, none, running);
  }
}
.organizations__form__item {
  .flight-text-input__error-message {
    max-width: 290px !important;
    text-align: left;
  }
}
