@import 'src/styles/variables';
@import 'src/styles/fonts';
@import 'src/styles/mixins';
@import 'src/styles/animations';

.organizations {
  height: 100%;
  padding-bottom: $spacing_7xlarge;
  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    z-index: 0;
  }
  &__header {
    display: flex;
    justify-content: space-between;
  }
  h1,
  h2 {
    @extend .sso_font;
    @extend .sso_font--xlarge;
    @extend .sso_font--xbold;
    margin-top: $spacing_2xlarge;
    margin-bottom: $spacing_xlarge;
  }
  &__select {
    @extend .sso_font;
    @extend .sso_font--large;
    @extend .sso_font--bold;
    color: map-get($primary-colors, 'black');
  }
  &__create-organization {
    @extend .sso_font;
    @extend .sso_font--bold;
    margin: 24px auto;
    margin-bottom: 74px;
    position: relative;
    z-index: 100;
    &__link {
      @extend .sso_font;
      @extend .sso_font--xsmall;
      text-align: center;
      margin-top: $spacing_5xlarge;
      color: map-get($blues, '500');
      &.flight-button--link:hover:enabled,
      &.flight-button--link:focus:enabled {
        outline: 2px solid map-get($blues, '800');
      }
    }
  }
  &__no-organization {
    @extend .sso_font;
    @extend .sso_font--xsmall;
    max-width: 418px;
    text-align: center;
    color: map-get($neutral-colors, '700');
    margin-top: 24px;
  }
  &__grid {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-bottom: $spacing_7xlarge;
  }
  &__organizationList {
    margin-top: $spacing_7xlarge;
    display: flex;
  }
  &__organizations-empty-state {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 420px;
    margin: auto;
    position: relative;
    z-index: 90;
    &__button {
      margin-top: $spacing_5xlarge;
      margin-bottom: $spacing_2xlarge;
      padding-inline-start: $spacing_7xlarge;
      padding-right: $spacing_7xlarge;
    }
    &__description {
      @extend .sso_font;
      @extend .sso_font--small;
      text-align: center;
      color: map-get($primary-colors, 'black');
      margin-top: $spacing_5xlarge;
      a {
        color: map-get($blues, '500');
      }
    }
  }
}
.empty_list__image {
  position: fixed;
  bottom: 0;
  z-index: 0;
  pointer-events: none;
}
