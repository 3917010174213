@import 'src/styles/variables';
@import 'src/styles/fonts';
@import 'src/styles/animations';

.SaveToolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  padding: 0 $spacing_7xlarge;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: #fff;
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(100px);
  transform-origin: top;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.3s;
  will-change: transform, opacity;
  > div {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    opacity: 0;
    transition: opacity 0.3 linear;
    button {
      margin-left: $spacing_2xlarge;
    }
  }
  &.show {
    transform: translateY(0px);
    opacity: 1;
    > div {
      opacity: 1;
    }
  }
}
