@import 'src/styles/variables';
@import 'src/styles/fonts';
@import 'src/styles/animations';

$MAIN_CLASS: 'IntegrationsList';
$CONTENT_CLASS: $MAIN_CLASS + '__content';
$FORM_CLASS: $MAIN_CLASS + '__form';
$ITEM_CLASS: $FORM_CLASS + '__item';
$LOADING_CLASS: $MAIN_CLASS + '__loading';

.full-width {
  max-width: 100%;
  margin: 0;
  padding: 0;
}
.container {
  max-width: 1106px;
  width: 100%;
}
.add-gap-12 {
  display: flex;
  gap: 12px;
}
.add-gap-20 {
  display: flex;
  gap: 20px;
}
#input-file-upload {
  display: none;
}
.share-code {
  font-size: 13px;
  max-width: 326px;
  line-height: 20px;
  position: relative;
  display: block;
  -webkit-appearance: none;
  appearance: none;
  background-color: map-get($neutral-colors, '50');
  width: calc(100% - 8px);
  padding: 12px 0 0 8px;
  height: calc(28px);
  border: none;
  border-bottom: 1px solid map-get($neutral-colors, '400');
  border-radius: 5px 5px 0 0;
  color: map-get($neutral-colors, '300');
}

.#{$MAIN_CLASS} {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__alert {
    .flight-button--primary {
      margin-left: $spacing_xlarge;
    }
    &.flight-modal--warning {
      .flight-button--primary {
        color: #fff;
        background-color: map-get($reds, '400');
        &:hover:enabled,
        &:focus:enabled {
          background-color: map-get($reds, '500');
        }
      }
      .flight-button--link,
      .flight-button--secondary {
        color: map-get($reds, '400');
        font-weight: bold !important;
      }
      .flight-button--secondary {
        box-shadow: inset 0 0 0 1px map-get($reds, '500');
      }
    }
    .flight-modal {
      &__header {
        font-weight: 800 !important;
        font-size: 20px;
        line-height: 25px;
        &__close {
          width: 40px;
          > svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
    &_footer {
      text-align: right;
      padding-bottom: $spacing_large;
    }
    &_content {
      color: map-get($neutral-colors, '700');
      font-size: 14px;
      line-height: 18px;
      &_form {
        display: flex;
        margin-top: $spacing_xlarge;
      }
    }
    &.sharing {
      max-width: 352px !important;
      .flight-modal__content {
        font-size: 14px;
        line-height: 18px;
        p {
          margin: 0 0 $spacing_2xlarge;
        }
        ul {
          margin: 0 0 0 $spacing_2xlarge;
          padding: 0;
          li {
            margin-bottom: $spacing_2xlarge;
          }
        }
        .checkboxes {
          margin-bottom: $spacing_7xlarge;
          .checkbox {
            display: flex;
            margin-bottom: $spacing_2xlarge;
            .flight-checkbox-wrapper {
              align-items: flex-start;
              .flight-checkbox-label {
                color: map-get($primary-colors, 'black');
                font-size: 14px;
                line-height: 18px;
              }
            }
          }
        }
      }
      .flight-modal__content + .flight-modal__footer {
        padding: $spacing_medium;
      }
    }
  }

  &__icon {
    padding: $spacing_7xlarge 0 0;
  }
}

.#{$CONTENT_CLASS} {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__wrapper {
    margin-bottom: 40px;
    max-width: 1106px;
    width: 100%;
    display: flex;
    flex-direction: column;
    .full-width & {
      max-width: 100%;
      > div {
        max-width: 1106px;
        margin-left: 10%;
        margin-right: 10%;
      }
    }
    &.with-separator {
      box-shadow: 0 1px 0 map-get($neutral-colors, '200');
      background-color: #fff;
    }
    h4 {
      margin: 3rem 0 0;
    }
  }
  &__filters {
    display: flex;
    gap: 0 20px;
  }
  &__share_button {
    &.flight-button--link {
      font-weight: bold !important;
    }
  }
  &__tabs {
    &-header {
      display: flex;
      &:after {
        display: block;
        height: 1px;
        background-color: map-get($neutral-colors, '200');
        position: absolute;
        left: 0;
        width: 100%;
        pointer-events: none;
        transform: translateY(44px);
      }
      &-item {
        &.flight-radio-button-wrapper {
          .flight-radio-button {
            &:focus-within,
            &:focus {
              outline: auto;
              outline-offset: 4px;
              outline-color: map-get($blues, '500');
            }
            height: auto;
            flex-direction: column-reverse;
            font-size: 16px;
            line-height: 20px;
            margin-right: $spacing_7xlarge;
            &__span {
              color: map-get($neutral-colors, '700');
              padding: 0;
            }
            &__radio {
              width: 100%;
              height: 4px;
              border: 2px solid transparent;
              margin-top: $spacing_2xlarge;
              border-radius: 0;
              &--selected {
                border-radius: 0;
                ~ .flight-radio-button__span {
                  color: map-get($primary-colors, 'black');
                  font-weight: bold;
                }
                border-color: map-get($blues, '500');
              }
            }
          }
        }
      }
    }
    &-integrations {
      border: 1px solid map-get($neutral-colors, '200');
      border-bottom: 1px solid transparent;
      &-empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: $spacing_7xlarge 0;
        color: map-get($primary-colors, 'black');
        font-size: 14px;
        line-height: 18px;
        height: 100%;
      }
      &-list {
        .CollapsibleMultiple {
          &__label {
            padding: 16px;
            align-items: flex-start;

            &__icon {
              fill: map-get($primary-colors, 'black');
            }
          }
          &__header {
            margin-left: 32px;
            h4 {
              margin: 0;
            }
            .infos {
              h4 {
                color: map-get($blues, '500');
              }
            }
            > div:last-child {
              font-weight: normal !important;
              font-size: 12px;
              line-height: 15px;
              color: map-get($primary-colors, 'black');
            }
          }
          div.CollapsibleMultiple__label__icon ~ div.CollapsibleMultiple__header {
            margin-left: 8px;
          }
        }
      }
    }
    &-content {
      margin: $spacing_5xlarge 0;
      &-table {
        border: 1px solid map-get($neutral-colors, '200');
        border-bottom: 0;
        width: calc(100% - 420px);
        margin-right: $spacing_7xlarge;
        height: 100%;
        @media only screen and (max-width: 1200px) {
          width: 100%;
          margin-right: 0;
        }
        &-no-results {
          text-align: center;
          font-size: 12px;
          padding-bottom: 1px;
          color: map-get($neutral-colors, '700');
          border-bottom: 1px solid map-get($neutral-colors, '200');
        }
        .flight-table-row__cell {
          font-size: 12px;
          line-height: 15px;
          color: map-get($neutral-colors, '700');
          &:first-child {
            width: 55%;
          }
          &:last-child {
            width: 45%;
          }
          > div {
            padding: $spacing_small $spacing_large;
            &.left-items,
            &.right-items {
              display: flex;
              gap: 20px;
              align-items: center;
            }
            &.right-items {
              float: right;
              text-align: right;
              strong {
                display: contents;
              }
              .to-delete {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                button {
                  font-size: 12px;
                  line-height: 15px;
                  padding: 0;
                  margin: $spacing_medium 0 0;
                  > span {
                    padding: 0;
                  }
                }
              }
            }
            .row-icon {
              display: flex;
              width: 32px;
              height: 32px;
              align-items: center;
              justify-content: center;
              overflow: hidden;
              img {
                object-fit: contain;
                width: 100%;
                height: auto;
              }
            }
            small {
              font-size: 12px;
              line-height: 15px;
              color: map-get($neutral-colors, '700');
            }
            em {
              font-weight: 800;
              font-size: 12px;
              line-height: 15px;
              font-style: normal;
              color: map-get($neutral-colors, '700');
            }
            p {
              margin-top: 0;
              margin-bottom: 0;
              font-size: 14px;
              line-height: 18px;
              padding: 0;
              color: map-get($primary-colors, 'black');
              strong {
                color: map-get($blues, '500');
                a {
                  color: map-get($blues, '500');
                  text-decoration: none;
                }
              }
            }
            &.is-shared {
              p {
                strong {
                  color: map-get($primary-colors, 'black');
                }
              }
            }
            &.is-deleted {
              .row-icon {
                img {
                  filter: grayscale(1);
                  opacity: 0.7;
                }
              }
              p {
                &:hover {
                  text-decoration: none !important;
                }
                strong {
                  color: map-get($neutral-colors, '700');
                }
              }
            }
            .flight-overflow-menu {
              .flight-button--minor:hover:enabled,
              .flight-button--minor:focus:enabled {
                color: #0851df;
                background-color: transparent;
              }
              .flight-button--minor:focus:enabled {
                outline: 2px solid $blue-600;
              }
              .flight-button--medium {
                background-color: transparent !important;
                padding: 0;
                width: 24px;
                height: 24px;
                &:hover {
                  background-color: transparent !important;
                }
                svg {
                  width: 24px;
                  height: 24px;
                  transform: rotate(90deg);
                }
              }
              .flight-overflow-menu__dropdown-list__group:last-of-type:not(:only-of-type) {
                .flight-overflow-menu__dropdown-list__group__option:last-child:only-child {
                  color: map-get($reds, '400') !important;
                }
              }
            }
          }
          &:hover {
            strong {
              text-decoration: underline;
            }
            div.is-shared,
            div.is-deleted {
              p {
                strong {
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
      &-empty {
        min-height: 30vh;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        p {
          font-size: 14px;
          line-height: 18px;
          a {
            text-decoration: none !important;
            color: map-get($blues, '500');
          }
        }
        > div {
          padding: $spacing_2xlarge 0 0;
        }
        svg {
          height: 80px;
          width: 80px;
        }
        #label-file-upload svg {
          height: 20px;
          width: 20px;
        }
      }
      &-columns {
        display: flex;
      }
      &-side {
        width: 380px;
        height: 260px;
        border: 1px solid map-get($neutral-colors, '200');
        box-sizing: border-box;
        // transform: translateY(-$spacing_7xlarge * 1.8);
        padding: $spacing_2xlarge * 1.4;
        @media only screen and (max-width: 1200px) {
          display: none;
        }
        .item {
          display: flex;
          margin-bottom: $spacing_xlarge;
          svg {
            width: 100%;
            margin-top: $spacing_xlarge;
          }
          h2 {
            font-size: 16px;
            line-height: 20px;
            color: map-get($primary-colors, 'black');
            font-weight: bold;
            margin: 0;
          }
          p {
            font-size: 12px;
            line-height: 15px;
            color: map-get($neutral-colors, '700');
            padding: 0;
          }
          .group {
            display: flex;
            flex-direction: column;
          }
        }
      }
      &-remapping {
        margin-top: -70px;
      }
    }
  }
  &__infos {
    box-sizing: border-box;
    display: flex;
    &-left {
      display: flex;
      flex-direction: column;
      flex: 100%;
    }
    &-wrapper {
      width: 100%;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: map-get($neutral-colors, '600');
      display: flex;
      flex-direction: column;
      border: 1px solid map-get($neutral-colors, '200');
      box-sizing: border-box;

      &.list {
        margin-top: $spacing_2xlarge;
        p {
          margin-top: 0;
          margin-bottom: $spacing_small;
          strong {
            color: map-get($blues, '500');
          }
        }
      }
    }
    &-item {
      display: flex;
      border-top: 1px solid map-get($neutral-colors, '200');
      padding: 20px 12px;
      margin: 0 $spacing_5xlarge;
      &-left {
        width: 40%;
      }
      &-right {
        color: map-get($primary-colors, 'black');
        min-width: 50%;
        &.upper {
          text-transform: uppercase;
        }
        &.capitalize {
          text-transform: capitalize;
        }
        &-small {
          color: map-get($neutral-colors, '700');
          text-transform: none;
          margin-top: 1rem;
          font-size: 12px;
          line-height: 15px;
          a {
            color: map-get($blues, '500');
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  &__heading-text {
    @extend .sso_font;
    @extend .sso_font--xlarge;
    @extend .sso_font--bold;
    margin-top: $spacing_7xlarge;
    margin-bottom: $spacing_7xlarge;
    display: flex;
    justify-content: space-between;
    max-width: 1106px;
    margin-left: 10%;
    // margin-right: 10%;
    span {
      button {
        margin-left: $spacing_2xlarge;
      }
    }
  }
  &__button {
    font-weight: normal;
    span {
      padding: 0;
    }
  }
  &__button_delete {
    color: #fff;
    background-color: map-get($reds, '400');
    &:hover:enabled,
    &:focus:enabled {
      background-color: map-get($reds, '500');
    }
  }
  &__button_link {
    font-weight: normal;
    span {
      padding: 0;
    }
    &.small {
      font-size: 12px;
      line-height: 15px;
      span {
        padding: 08px 12px;
      }
    }
    color: map-get($blues, '500');
    &:hover:enabled,
    &:focus:enabled {
      color: map-get($blues, '500');
      span {
        color: map-get($blues, '500');
      }
    }
    &-back {
      align-self: flex-start;
      margin-bottom: $spacing_xlarge;
    }
  }
  &__button_delete_link {
    font-weight: normal;
    span {
      padding: 0;
    }
    color: map-get($reds, '400');
    &:hover:enabled,
    &:focus:enabled {
      color: map-get($reds, '400');
      span {
        color: map-get($reds, '400');
      }
    }
  }
  &__button_manage {
    color: map-get($blues, '500');
    float: right;
    font-weight: normal;
    span {
      padding: 0;
    }
    &:hover:enabled,
    &:focus:enabled {
      color: map-get($blues, '500');
      span {
        color: map-get($blues, '500');
      }
    }
  }
  &__modal {
    max-width: 480px !important;
  }
  &__input {
    margin-top: $spacing_2xlarge;
    &__inline {
      margin-right: $spacing_large;
    }
  }
  &__row {
    margin-top: $spacing_5xlarge;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-end;
  }
  &__link {
    margin-top: 80px;
    padding: $spacing_medium 0;
    @extend .sso_font--small;
    text-decoration: none;
    &:active,
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  form {
    position: relative;
  }
  &__info {
    width: fit-content;
    z-index: 200;
  }
  &__loading {
    border-radius: 10px;
    z-index: 99;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 60px);
    width: 100%;
    left: 0;
    top: 60px;
    background-color: rgba(255, 255, 255, 0.9);

    svg:last-child {
      position: absolute;
      visibility: visible;
      fill: map-get($blues, '500');
      // check _mixins.scss for more details
      @include animation(spin, 0s, 0.75s, linear, infinite, normal, none, running);
    }
  }
}
.#{$ITEM_CLASS} {
  &__hide {
    display: none;
    visibility: hidden;
  }
  &__group {
    display: flex;
    flex-wrap: wrap;
    // padding: $spacing_7xlarge;
  }
  &__remove {
    color: map-get($reds, '400') !important;
    padding-top: 10px;
  }
  &__image-field {
    display: flex;
    width: 80px;
    height: 80px;
    /* N200 */
    border: 1px solid map-get($neutral-colors, '100');
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    flex: 0 0 80px;
    margin-right: 20px;
    transition: all 0.2s linear;
  }
  width: 100%;
  min-width: 300px;
  display: flex;
  &__button {
    @extend .#{$ITEM_CLASS};
    margin-top: $spacing_5xlarge;
    margin-bottom: $spacing_2xlarge;
  }
  &__edit {
    position: relative;
    width: 100%;

    &:focus-within {
      svg,
      label {
        opacity: 0;
        pointer-events: none;
        transition: all 0.1s linear;
      }
    }
    label {
      position: absolute;
      pointer-events: none;
      font-weight: 800;
      font-size: 24px;
      line-height: 30px;
      border: 0;
      padding-top: 4px;
      padding-inline-start: 2.5px;
      color: transparent;
      opacity: 0;
      transition: opacity 0.2s linear;
      &.loaded {
        opacity: 1;
      }
    }
    &-input {
      font-weight: 800;
      font-size: 20px;
      line-height: 25px;
      font-family: 'Muli', sans-serif;
      border: 0;
      padding-top: 5px;
      border-bottom: 2px solid transparent;
      transition: all 0.2s ease;
      width: 100%;
      display: none;
      &.loaded {
        display: block;
      }
      &:-internal-autofill-selected,
      &:-webkit-autofill {
        background-color: transparent !important;
        appearance: none;
        box-shadow: 0 0 0 30px white inset;
        -webkit-box-shadow: 0 0 0 30px white inset;
        border-bottom: 2px solid #fff;
      }
      &:focus {
        border-bottom: 2px solid map-get($blues, '500');
        outline: none;
        width: 100%;
        padding-inline-start: 5px;
      }
      &:disabled {
        background-color: transparent;
      }
    }
    .status {
      color: map-get($neutral-colors, '700');
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      margin-top: 6px;
      margin-bottom: 1rem;
    }
  }
  &__input {
    margin-top: $spacing_2xlarge;
    &__inline {
      margin-right: $spacing_large;
    }
  }
  &__row {
    @extend .#{$ITEM_CLASS};
    margin-top: $spacing_5xlarge;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-end;
  }
}

.loading__users_integrations {
  width: 100%;
  margin-top: $spacing_7xlarge;
  text-align: center;
}

.modal_buttons {
  text-align: right;
  button {
    margin-left: 8px;
  }
}

.#{$LOADING_CLASS} {
  width: 100%;
  text-align: center;
}

.flight-tag {
  border-radius: 0 !important;
  white-space: nowrap !important;
  font-weight: bold !important;
  font-size: 12px !important;
  line-height: 18px !important;
  padding: 4px 8px !important;
  display: inline-flex;
  &--success {
    background-color: map-get($greens, '50') !important;
    color: map-get($greens, '600') !important;
  }
}
.flight-modal-wrapper .flight-modal .flight-modal__header {
  padding: 20px 50px 12px 24px !important;
  position: relative !important;
}
.flight-modal-wrapper .flight-modal--small {
  max-width: 452px !important;
}
