@import 'src/styles/variables';

$MAIN_CLASS: 'ConfigPreview';

.#{$MAIN_CLASS} {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  border: 0;
  padding: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: map-get($primary-colors, 'black');
  &-error {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 360px;
    p {
      font-size: 14px;
      line-height: 18px;
      color: map-get($neutral-colors, '700');
    }
    svg {
      max-width: 200px;
      height: auto;
    }
  }
  > section {
    margin-bottom: $spacing_5xlarge;
    h4 {
      font-weight: bold;
      color: map-get($primary-colors, 'black');
      font-size: 16px;
      line-height: 20px;
      margin: 0;
      display: flex;
      align-items: center;
    }
    > div,
    .columns .component div.ItemHeader + div {
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      align-items: center;
      padding-top: $spacing_medium;
      position: relative;
      &.radio {
        display: flex;
        align-items: baseline;
      }
      .flight-radio-button-wrapper,
      .flight-checkbox-wrapper {
        margin-right: $spacing_large;
      }
    }
    .section {
      &__item {
        display: flex;
      }
    }
    .help {
      svg {
        width: 16px;
        height: 16px;
        margin-left: $spacing_small;
        transform: translateY(2.5px);
        path {
          fill: map-get($neutral-colors, '400');
        }
      }
    }
  }
}
