@import 'src/styles/variables';
@import 'src/styles/fonts';
@import 'src/styles/mixins';

.project {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &__create-project {
    &.flight-button--primary {
      width: 342px;
      height: 241px;
      background-color: #fff;
      border: 1px solid map-get($blues, '500');
      color: map-get($blues, '500');
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      transition: all 0.3s ease;
      margin-bottom: $spacing_5xlarge;
      svg {
        width: 40px;
        height: 40px;
        transition: fill 0.3s ease;
        fill: map-get($blues, '500');
        margin-bottom: 15px;
        transition: transform 0.4s ease 0.2s;
      }
      &:hover {
        color: #fff;
        svg {
          transform: rotate(360deg);
          fill: #fff;
        }
      }
    }
  }
  &__content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__heading-text {
      @extend .sso_font;
      @extend .sso_font--xlarge;
      @extend .sso_font--bold;
      margin-top: $spacing_7xlarge;
      margin-bottom: $spacing_7xlarge;
      color: map-get($neutral-colors, '900');
    }
    &__search {
      margin-bottom: $spacing_7xlarge;
      display: flex;
      gap: $spacing_2xlarge;
    }
  }
  &__wrapper {
    margin-bottom: 100px;
    max-width: 1108px;
    width: 100%;
    display: flex;
    flex-direction: column;
    h4 {
      margin: 3rem 0 0;
    }
  }

  &__projectList {
    display: flex;
    flex-wrap: wrap;
    gap: 5px 40px;
    padding-bottom: 180px;
  }
  &__projects-empty-state {
    display: flex;
    align-items: center;
    height: 241px;
    margin-left: $spacing_7xlarge;
    margin-right: auto;
    &-desc {
      font-style: italic;
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
      color: map-get($neutral-colors, '700');
      max-width: 406px;
    }
  }
  &__logo {
    height: 30px;
    width: 103px;
  }
}
