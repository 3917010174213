@import 'src/styles/variables';
@import 'src/styles/fonts';
@import 'src/styles/animations';

.UpdatedVersionNotification {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 $spacing_2xlarge;
  position: fixed;
  bottom: $spacing_2xlarge;
  left: 30%;
  width: 40%;
  right: 0;
  z-index: 1000;
  background: #fff;
  border-radius: $spacing_large;
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.1);
  opacity: 0;
  transform: translateY(60px);
  transform-origin: top;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0s;
  will-change: transform, opacity;
  box-shadow: 0 0 30px rgba(5, 19, 54, 0.3);
  > div {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    opacity: 0;
    transition: opacity 0.3s linear;
    min-width: 170px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &.show {
    transform: translateY(0px);
    transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.3s;
    opacity: 1;
    > div {
      opacity: 1;
    }
  }
}
