@import 'src/styles/variables';
@import 'src/styles/fonts';
@import 'src/styles/animations';

$MAIN_CLASS: 'ConfigTemplate';

.#{$MAIN_CLASS} {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: $spacing_7xlarge * 2;
  .section {
    &__tabs {
      &-header {
        display: flex;
        margin-top: $spacing_2xlarge;
        margin-bottom: $spacing_5xlarge;
        box-shadow: 0 0.5px 0 map-get($neutral-colors, '200');
        > a {
          margin-left: auto;
          font-size: 14px;
          line-height: 18px;
          text-decoration: none;
        }
        &-item {
          &.flight-radio-button-wrapper {
            .flight-radio-button {
              height: auto;
              flex-direction: column-reverse;
              font-size: 14px;
              line-height: 18px;
              margin-right: $spacing_7xlarge;
              &__span {
                color: map-get($neutral-colors, '700');
                padding: 0;
              }
              &__radio {
                width: 100%;
                height: 4px;
                border: 2px solid transparent;
                margin-top: $spacing_large;
                border-radius: 0;
                &--selected {
                  border-radius: 0;
                  ~ .flight-radio-button__span {
                    color: map-get($primary-colors, 'black');
                    font-weight: bold;
                  }
                  border-color: map-get($blues, '500');
                }
              }
            }
          }
        }
      }
    }
  }
}
