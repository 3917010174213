@use 'sass:math';

@import 'src/styles/variables';
@import 'src/styles/fonts';
@import 'src/styles/animations';

$MAIN_CLASS: 'Treeview';
$line-height: 36px;

.#{$MAIN_CLASS} {
  width: 100%;
  height: 100%;
  display: flex;
  border: 1px solid map-get($neutral-colors, '100');
  box-sizing: border-box;
  flex-wrap: wrap;
  padding: ($spacing_2xlarge * 0.5) $spacing_2xlarge;

  > div {
    color: map-get($blues, '500');
    font-size: 14px;
    line-height: 32px;
    width: 100%;
  }

  ul {
    margin: 0 0 0 math.div($spacing_2xlarge, 1.5);
    padding: 0;
    list-style: none;
    color: map-get($neutral-colors, '200');
    position: relative;
    &:before {
      content: '';
      display: block;
      width: 0;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      border-left: 1px solid;
    }
    li {
      margin: 0;
      padding: 0 0 0 ($spacing_2xlarge * 1.5);
      position: relative;
      font-weight: normal;
      font-size: 14px;
      line-height: 32px;
      color: map-get($blues, '500');
      &:before {
        content: '';
        display: block;
        width: $spacing_2xlarge;
        height: 0;
        border-top: 1px solid;
        margin-top: -1px; /* border top width */
        position: absolute;
        top: ($line-height * 0.5);
        color: map-get($neutral-colors, '200');
        left: 0;
      }
      &:last-child:before {
        background: white; /* same with body background */
        height: auto;
        top: ($line-height * 0.5);
        color: map-get($neutral-colors, '200');
        bottom: 0;
      }
    }
  }
  > ul {
    margin: 0;
    &:before {
      display: none;
    }
    > li {
      padding: 0 30px 0 0;
      display: block;

      &:before {
        display: none;
      }
    }
  }
}
