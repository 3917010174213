@import 'src/styles/variables';
@import 'src/styles/fonts';
@import 'src/styles/animations';

$MAIN_CLASS: 'signin';
$FORM_CLASS: $MAIN_CLASS + '__form';
$ITEM_CLASS: $FORM_CLASS + '__item';
$image_height: 40px;

.#{$MAIN_CLASS} {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  &__description {
    &.center {
      justify-content: center !important;
      text-align: center;
    }
    p {
      text-align: center;
    }
    .flight-button__label {
      font-size: 16px;
      padding: 0;
    }
  }

  &__logo {
    width: auto;
    height: $image_height;
    position: relative;
    right: 18px;
  }

  &__heading-text {
    @extend .sso_font;
    @extend .sso_font--xlarge;
    @extend .sso_font--bold;
    margin-top: $spacing_7xlarge;
    margin-bottom: $spacing_xlarge;
  }
}

.#{$FORM_CLASS} {
  width: 50vw;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.#{$ITEM_CLASS} {
  width: 50%;
  display: flex;
  align-items: center;

  &__button {
    @extend .#{$ITEM_CLASS};
    margin-top: $spacing_5xlarge;
    margin-bottom: $spacing_2xlarge;
    width: 50% !important;
  }

  &__checkbox {
    margin-top: 0;
    .flight-checkbox-wrapper {
      &:focus {
        .flight-checkbox {
          outline: auto;
        }
      }
    }
  }
  @keyframes fill-checkbox {
    to {
      stroke-dashoffset: 0;
    }
  }

  &__signup {
    @extend .sso_font--small;
    margin-top: $spacing_2xlarge;
    a {
      color: #0851df;
    }
    small {
      color: map-get($neutral-colors, '700');
      font-size: 12px;
      > a {
        font-size: 12px;
      }
    }
    hr {
      border: none;
      box-shadow: 0 1px 0 map-get($neutral-colors, '100');
      height: 1px;
      margin-bottom: $spacing_2xlarge;
    }
  }
  &__segmented-input {
    .character {
      border: none;
      font-size: 20px;
      border-radius: 8px;

      /* light theme */
      color: #272729;
      background-color: #f6f5fa;
      box-shadow: 0 2px 0 #e4e2f5;
      width: 68px;
      line-height: 70px;
    }
    .container {
      padding: 0;
      height: 70px;
    }
  }
  &__input {
    margin-top: $spacing_2xlarge;
    width: 100%;
    max-width: 100%;
    &-mask {
      border-right: 1px solid rgba(0, 0, 0, 0.2);
      width: 48px;
      height: 58px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 32px;
      position: relative;
      &:last-child {
        border-right: none;
      }
    }
  }

  &__row {
    @extend .#{$ITEM_CLASS};
    margin-top: $spacing_5xlarge;
    justify-content: space-between;
    &.center {
      justify-content: center !important;
    }
  }

  &__link {
    @extend .sso_font--small;
    text-decoration: none;
    a {
      color: #0851df;
    }
    &:active,
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.flight-text-input__error-message {
  animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
  animation-delay: 0.4s;
}
