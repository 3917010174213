@import 'src/styles/variables';

.flight-text-area {
  padding: 0 !important;
  &__textarea {
    min-height: 100px;
    box-sizing: border-box;
    width: 100%;
  }
}
