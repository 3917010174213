@import 'src/styles/variables';
@import 'src/styles/mixins';

.TableError {
  padding: $spacing_large !important;
  background-color: map-get($reds, '50');
  margin-bottom: $spacing_medium;
  > svg {
    margin-right: $spacing_medium;
  }
}
.TableEdit {
  border-collapse: collapse;
  border-spacing: 0;
  text-align: left;
  width: 100%;
  thead {
    background: map-get($neutral-colors, '50');
  }
  td,
  th {
    border-color: map-get($neutral-colors, '200');
    border-style: solid;
    border-width: 1px;
    font-size: 14px;
    line-height: 18px;
    padding: 10px 12px;
    vertical-align: middle;
    font-weight: normal;
    &.remove {
      width: 40px;
      text-align: center;
    }
    .flight-text-input__input {
      background-color: transparent;
      border: 0;
      @include prefix-pseudo-element(placeholder) {
        color: map-get($neutral-colors, '400');
        opacity: 1;
      }
      &:focus {
        @include prefix-pseudo-element(placeholder) {
          opacity: 1;
        }
      }
    }
  }
  td {
    padding: 0;
    &.error {
      box-shadow: inset 0 0 0 1px map-get($reds, '500');
    }
  }
}
.flight-button--link.add-row {
  span {
    font-weight: bold;
  }
  > svg {
    width: 14px;
    height: 14px;
  }
}
