@import 'src/styles/variables';
@import 'src/styles/fonts';
@import 'src/styles/mixins';
@import 'src/styles/animations';

$MAIN_CLASS: 'user';
$CONTENT_CLASS: $MAIN_CLASS + '__content';
$ITEM_CLASS: $CONTENT_CLASS + '__item';

.#{$MAIN_CLASS} {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__icon {
    padding: $spacing_7xlarge 0 0;
  }
}

.#{$CONTENT_CLASS} {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__wrapper {
    margin-bottom: 40px;
    max-width: 760px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  &__button_link.flight-button--link {
    font-weight: normal;
    color: map-get($blues, '500');
    align-self: flex-start;
    margin-bottom: 16px;
    span {
      padding: 0;
    }
    &.small {
      font-size: 12px;
      line-height: 15px;
      span {
        padding: 8px 12px;
      }
    }
    &:hover:enabled,
    &:focus:enabled {
      color: map-get($blues, '500');
      span {
        color: map-get($blues, '500');
      }
    }
    &-back {
      align-self: flex-start;
      margin-bottom: $spacing_xlarge;
    }
  }
  &__avatar.flight-avatar {
    width: 80px;
    height: 80px;
    font-size: 2rem;
    border-radius: 12px !important;
    &-wrapper {
      width: 100%;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      color: map-get($neutral-colors, '400');
      display: flex;
      align-items: center;
      > p {
        margin-left: $spacing_5xlarge;
      }
    }
  }
  &__infos {
    &-wrapper {
      width: 100%;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      color: map-get($neutral-colors, '600');
      display: flex;
      flex-direction: column;
      border-top: 1px solid map-get($neutral-colors, '200');
      margin-top: $spacing_7xlarge;
    }
    &-item {
      display: flex;
      border-bottom: 1px solid map-get($neutral-colors, '200');
      padding: 20px 0;
      &-left {
        width: 40%;
      }
      &-right {
        display: flex;
        align-items: center;
        color: map-get($primary-colors, 'black');
        button {
          &:first-child {
            margin-right: $spacing_xlarge;
          }
        }
        &__icon-button {
          cursor: pointer;
          background: none;
          border: 0;
          height: 20px;
          width: 20px;
          padding: 0;
          margin: 0 0 0 6.5px;
          svg {
            fill: $blue-500;
            width: 20px;
            height: 20px;
          }
          &:hover svg {
            fill: $blue-600;
          }
        }
      }
    }
  }
  &__tos {
    display: flex;
    padding: $spacing_7xlarge 0;
    font-size: 12px;
    justify-content: right;
    color: map-get($neutral-colors, '200');
    a {
      &:first-child {
        padding-right: $spacing_2xlarge;
      }
      &:last-child {
        padding-left: $spacing_2xlarge;
      }
    }
  }
  &__heading-text {
    @extend .sso_font;
    @extend .sso_font--xlarge;
    @extend .sso_font--bold;
    margin-top: $spacing_7xlarge;
    margin-bottom: $spacing_7xlarge;
    display: flex;
    flex-direction: column;
  }
  &__button.flight-button--link {
    font-weight: normal;
    span {
      padding: 0;
    }
  }
  &__button_deactivate.flight-button--link {
    font-weight: normal;
    span {
      padding: 0;
    }
    color: map-get($reds, '400');
    &:hover:enabled,
    &:focus:enabled {
      color: map-get($reds, '400');
      span {
        color: map-get($reds, '400');
      }
    }
  }
  &__modal {
    max-width: 480px !important;
    overflow: hidden;
  }
  &__input {
    margin-top: $spacing_2xlarge;
    &__inline {
      margin-right: $spacing_large;
    }
  }
  &__row {
    margin-top: $spacing_5xlarge;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-end;
  }
  &__signup {
    margin-top: 80px;
    float: right;
    padding: $spacing_medium $spacing_2xlarge;
    margin-right: 12px;
  }
  &__link {
    margin-top: 80px;
    padding: $spacing_medium 0;
    @extend .sso_font--small;
    text-decoration: none;
    &:active,
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  &__password-sent {
    display: flex;
    align-items: center;
    flex-direction: column;
    p {
      text-align: center;
      margin-top: 0;
      margin-bottom: $spacing_5xlarge;
    }
    h3 {
      font-weight: bold;
      font-size: 20px;
      line-height: 25px;
    }
  }
  form {
    position: relative;
  }
  &__info {
    width: fit-content;
  }
}
