@import 'src/styles/variables';
@import 'src/styles/fonts';
@import 'src/styles/animations';

$MAIN_CLASS: 'create-project__modal';
$LOADING_CLASS: $MAIN_CLASS + '__loading';

.#{$LOADING_CLASS} {
  position: absolute;
  width: 300px;
  left: calc(50% - 150px);
  top: calc(50% - 50px);
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 32px;
  border-radius: 10px;
  box-shadow: 0 0 30px rgba(5, 19, 54, 0.3);
  background-color: #fff;
  svg {
    visibility: hidden;
  }

  svg:last-child {
    position: relative;
    visibility: visible;
    fill: map-get($blues, '500');
    // check _mixins.scss for more details
    @include animation(spin, 0s, 0.75s, linear, infinite, normal, none, running);
  }
  h3 {
    position: relative;
    z-index: 1000;
    margin-top: 120px;
  }
}

.create-project__modal {
  .create-project__modal__header {
    padding-top: $spacing_large * 3.6;
    padding-inline-start: 0;
    @extend .sso_font--large;
    @extend .sso_font--bold;
    display: inline;
  }
  .flight-modal__content {
    overflow: hidden !important;
    @media only screen and (max-width: 1150px) {
      overflow-y: visible !important;
    }
  }
  .flight-modal-wrapper .flight-modal .flight-modal__content {
    max-height: 95vh !important;
    padding: 12px 40px 0 40px;
    .create-project__modal__loading {
      left: calc(50% - 182px);
    }
  }

  .flight-modal-wrapper .flight-modal.flight-modal--large {
    max-width: 95vw;
    width: 20%;
  }
  .flight-modal-wrapper {
    z-index: 200;
  }
  .flight-modal-wrapper .flight-modal .flight-modal__footer {
    border-top: 1px solid rgba(5, 19, 54, 0.1);
    padding: $spacing-2xlarge $spacing-7xlarge;
    margin-top: $spacing-5xlarge;
  }
  &__content {
    position: relative;
  }
  .create-project__modal__row {
    padding-top: $spacing-5xlarge;
    height: $spacing-xlarge * 6;
    display: flex;
    &__icon {
      border: 1px solid $border-color;
      width: 66px;
      height: 66px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 8px;
    }
    &__label {
      float: right;
      margin-left: $spacing_2xlarge;
      padding-top: 8px;
      @extend .sso_font--small;
      @extend .sso_font--bold;
      font-size: 14px;
      line-height: 18px;
      color: map-get($neutral-colors, '900');
      vertical-align: middle;
      width: 100%;
      .flight-tooltip__content {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        svg {
          margin-left: 5px;
          cursor: pointer;
        }
      }
      &__field {
        .flight-text-input__icon-trailing {
          top: 30% !important;
        }
        padding-top: $spacing-small * 2;
      }
    }
  }
  .create-project__modal__select {
    margin-bottom: $spacing-2xlarge;
    &__label {
      @extend .sso_font--small;
      @extend .sso_font--bold;
      font-size: 14px;
      line-height: 18px;
      color: map-get($neutral-colors, '900');
    }
    .flight-select {
      margin-top: $spacing-medium;
    }
  }
  .create-project__modal__radio__label {
    @extend .sso_font--small;
    @extend .sso_font--bold;
    font-size: 14px;
    line-height: 18px;
    color: map-get($neutral-colors, '900');
  }
  .create-project__modal__radio__group {
    @extend .sso_font--small;
    @extend .sso_font--bold;
    font-size: 14px;
    line-height: 18px;
    color: map-get($neutral-colors, '900');
    &__icon {
      border-radius: 5px;
      border: 1px solid $border-color;
      &:hover {
        border: 1px solid blue;
      }
      width: 169px;
      height: 25px;
      border-radius: 5px;
      border: 1px solid #e6e7eb;
      padding: 6px;
      padding-top: 10px;
      margin: 13px;
      margin-left: -1px;
    }
  }
  .create-project__modal__container {
    @media only screen and (max-width: 1150px) {
      height: 0;
    }
    height: 533px;
    width: 685px;
    position: relative;
    float: right;
  }
  .create-project__modal__placeholder__jdenticon {
    @media only screen and (max-width: 1150px) {
      display: none;
    }
    z-index: 100;
    position: absolute;
    left: 49%;
    top: 26.9%;
    visibility: visible;
  }
  .create-project__modal__placeholder__name {
    @media only screen and (max-width: 1150px) {
      display: none;
    }
    z-index: 100;
    position: absolute;
    left: 54%;
    top: 27.2%;
    white-space: nowrap;
    overflow: hidden;
    max-width: 45%;
    text-overflow: ellipsis;
    &__env {
      @extend .sso_font--small;
      color: $neutral-color-600;
    }
  }
  .create-project__modal__subdomain__label {
    @extend .sso_font--small;
    @extend .sso_font--bold;
    // padding-top: $spacing-small * 2;
    font-size: 14px;
    line-height: 18px;
    color: map-get($neutral-colors, '900');
  }
  .create-project__modal__subdomain__description {
    @extend .sso_font--small;
    padding-top: $spacing-small * 2;
    padding-bottom: $spacing-small;
    // max-width: 45%;
    color: $neutral-color-600;
    font-size: 12px;
    font-weight: normal;
    line-height: 15px;
    a {
      text-decoration: none;
      color: map-get($blues, '500');
    }
  }
  .create-project__modal__placeholder__img {
    @media only screen and (max-width: 1150px) {
      display: none;
    }
    position: absolute;
    top: 50px;
    right: -90px;
    padding-inline-start: 0;
    visibility: visible;
  }
  .create-project__modal__placeholder__text {
    @media only screen and (max-width: 1150px) {
      display: none;
    }
    @extend .sso_font--small;
    font-size: $font-size-xsmall;
    color: $neutral-color-600;
    z-index: 100;
    position: absolute;
    white-space: nowrap;
    overflow: hidden;
    max-width: 70%;
    text-overflow: ellipsis;
    left: 36%;
    top: 19.1%;
  }
  .create-project__modal__subdomain__edit {
    width: 100%;
    height: 44px;
    margin-top: 5px;
    border-radius: 5px;
    padding: 6px 8px 0 8px;
    box-sizing: border-box;
    border: 0.5px solid $border-color;
    &__icon {
      cursor: pointer;
      padding: 5px;
      height: 18px;
      width: 18px;
      margin-top: 2px;
      fill: $neutral-color-600;
    }
    &__text {
      color: $neutral-color-700;
      margin-top: 6px;
      position: absolute;
      white-space: nowrap;
      overflow: hidden;
      // max-width: 30%;
      text-overflow: ellipsis;
      font-size: 12px;
      line-height: 15px;
    }
    &__label {
      margin-top: 20px;
      float: left;
    }
    &__field {
      margin-top: 6px;
      left: 0;
      &--is-dev {
        bottom: 30px;
        left: 43% !important;
      }
    }
    &__domain {
      &__holder {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
      }
      margin-left: 12px;
      padding-top: 4px;
      font-size: 12px;
      line-height: 15px;
      &--is-dev {
        margin-top: 20px;
        float: right;
        padding-right: 2%;
      }
    }
    &__error {
      margin-top: 10px !important;
    }
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
    @media only screen and (max-width: 1150px) {
      // margin-inline-start: 0%;
    }
    &__cancel-btn {
      right: 15px;
    }
  }
}

.flight-text-input__error-message {
  max-width: 354px;
}
