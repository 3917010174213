@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/fonts';
@import '../../styles/animations';

$MAIN_CLASS: 'org_users_list';
$CONTENT_CLASS: $MAIN_CLASS + '__content';

.#{$CONTENT_CLASS} {
  &__infos {
    &-item {
      display: flex;
      border-bottom: 1px solid map-get($neutral-colors, '200');
      padding: 12px;
      align-items: center;
      &-left {
        width: 50%;
        small {
          font-size: 12px;
          line-height: 15px;
          color: map-get($neutral-colors, '700');
        }
      }
      &-right {
        color: map-get($primary-colors, 'black');
        text-transform: capitalize;
        width: 50%;
        .verified {
          color: #10b2fb;
          display: flex;
          align-items: center;
          svg {
            margin-right: 6px;
            fill: #10b2fb;
          }
        }
        &-small {
          color: map-get($neutral-colors, '700');
          text-transform: none;
          margin-top: 1rem;
          font-size: 12px;
          line-height: 15px;
          a {
            color: map-get($blues, '500');
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
