@import 'src/styles/variables';
@import 'src/styles/fonts';
@import 'src/styles/animations';

.PluginAttributesPanel {
  height: calc(100% - 160px);
  position: relative;
  &__wrapper {
    max-height: calc(100% - 35px);
    overflow: auto;
  }
  &__parameters {
    &-items {
      > div {
        display: flex;
        margin-bottom: $spacing_large;
        gap: 10px;
      }
    }
    &-btn {
      margin: 0 !important;
    }
  }
  &__input {
    margin: $spacing_large 0 $spacing_5xlarge 0 !important;
    &-add {
      > span {
        font-weight: 700;
      }
    }
    &-group {
      display: flex;
      gap: 30px;
      width: 100%;
      > div {
        width: 50%;
        &.radio-buttons {
          label {
            font-size: 14px;
            line-height: 20px;
            color: map-get($neutral-colors, '600');
          }
          display: flex;
          flex-direction: column;
          > div {
            display: flex;
            gap: $spacing_xlarge;
          }
        }
      }
    }
    &-expiry {
      display: flex;
      justify-content: space-between;
    }
    &-footer {
      width: 100%;
      border-top: 1px solid map-get($neutral-colors, '300');
      display: flex;
      padding: $spacing_xlarge 0;
      justify-content: flex-end;
      gap: $spacing_xlarge;
      position: sticky;
      bottom: 0;
      background-color: #fff;
      margin-bottom: 0 !important;
      margin-top: $spacing_2xlarge !important;
      box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.1);
      z-index: 10;
    }
  }
  &__textarea {
    .flight-text-area__span {
      transition: all 0.15s ease;
      top: 10px;
      left: 8px;
      transform-origin: 0 0;
      pointer-events: none;
    }
    textarea {
      border-radius: 5px 5px 0px 0px;
      border-color: transparent;
      border-bottom-color: map-get($neutral-colors, '300');
      background-color: map-get($neutral-colors, '50');
      padding-top: $spacing_xlarge;
      min-height: 70px;
      &:empty {
        &:focus {
          & ~ .flight-text-area__span {
            transform: translateY(-8px) scale(0.85);
            left: 8px;
          }
        }
      }
      &:not(:empty) {
        & ~ .flight-text-area__span {
          transform: translateY(-8px) scale(0.85);
          left: 8px;
        }
      }
    }
  }
}
