@import 'src/styles/variables';
@import 'src/styles/fonts';

.flight-avatar {
  border-radius: 5px;
}

.flight-nav {
  &__header {
    z-index: 50 !important;
    border-bottom: 1px solid map-get($neutral-colors, '200');
  }
  &__header__logo {
    margin-left: 32px !important;
    &:focus {
      &:hover {
        outline: 2px solid map-get($blues, '500');
      }
      outline: 2px solid map-get($blues, '800');
    }
    svg {
      height: 24px;
    }
  }
}

.show__info {
  width: fit-content !important;
  z-index: 150 !important;
}
